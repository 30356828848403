<template>
  <b-modal
    size="lg"
    id="sc-offering"
    class="advisor-info-modal"
    title="Offering Classification"
    ok-only
  >
    <p>
      {{ siteName }} has developed an offering classification system to provide
      relative prioritization of service expansion. Each solution within an
      offering category is examined relative to the percentage of peers offering
      the solution. The classification system rewards institutions for earlier
      adoption and provision of products. For example, a financial institution
      will get a higher classification if it offers a solution that is not
      offered by a high percentage of peers. Conversely, an institution will get
      a very low classification if it does not offer a solution offered by a
      high percentage of peers. An institution not offering a niche service will
      not result in the lowest classification but will get a relatively moderate
      classification since the service is not highly offered by peers. The total
      classification for an offering category is the average of the
      classification for the individual services within the category.
    </p>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SolutionHierarchyClassificationModal',
  computed: {
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return this.partnerSite ? 'Performance Intelligence' : 'FI Navigator'
    }
  }
}
</script>
