<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-1 sub-card"
        header-class="px-2 py-1"
      >
        <div slot="header">
          <div class="row ml-0 mr-0 mt-1">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div class="row">
                <b-col md="12" class="pl-2">
                  <div>
                    <h2 class="section--heading">SOLUTION HIERARCHY</h2>
                  </div>
                  <span class="section--heading-note">Where to focus?</span>
                </b-col>
              </div>
            </b-col>
            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="row">
                <b-col md="6">
                  <div>
                    <div>
                      <h6 class="section--heading">
                        Offering
                        <i
                          class="icon-info icons small-line-icon"
                          v-b-modal.offering-info
                        ></i>
                      </h6>
                    </div>
                    <span class="section--heading-note"
                      >Navigation for Offering Enhancement</span
                    >
                  </div>
                </b-col>
                <SolutionHierarchyOfferingModal></SolutionHierarchyOfferingModal>
                <b-col md="2" class="section--heading-offering px-1">
                  <h6 class="section--heading-note mb-0 ml-1">
                    <div class="row pr-2">
                      <div class="col-sm-9 pr-0">Offering</div>
                      <div class="col-sm-3 pl-0">
                        <i
                          class="icon-info icons small-line-icon"
                          v-b-modal.sc-offering
                        ></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">Classification</div>
                    </div>
                  </h6>
                  <SolutionHierarchyClassificationModal></SolutionHierarchyClassificationModal>
                  <b-popover
                    target="sc-offering"
                    triggers="hover focus"
                    boundary="viewport"
                  >
                  </b-popover>
                </b-col>
                <b-col md="3" class="section--heading-offering px-1">
                  <h6 class="section--heading-note mb-0 ml-1">
                    <div class="row">
                      <div class="col-sm-7">Next Most</div>
                      <div class="col-sm-3 pl-0">
                        <i
                          class="icon-info icons small-line-icon"
                          v-b-modal.sc-likely
                        ></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">Likely Solution</div>
                    </div>
                  </h6>
                  <SolutionHierarchyNextSolutionModal></SolutionHierarchyNextSolutionModal>
                </b-col>
                <b-col md="1" class="pt-3 pl-0 pr-2">
                  <span
                    class="icon-printer pull-right"
                    @click="$emit('print-page')"
                  ></span>
                  <span
                    v-b-tooltip.hover
                    title="Submit a Service Update"
                    class="fa fa-pencil-square-o pull-right pr-2 cursor-pointer"
                    v-b-modal.bankServiceVPModal
                  ></span>
                </b-col>
              </div>
            </b-col>
          </div>
        </div>
        <v-wait for="fetchServiceData">
          <template slot="waiting">
            <div class="row">
              <b-col sm="12" md="4" class="pl-3 pr-2">
                <div class="sub-card-left rounded show-bank-details">
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-heading />
                    <content-placeholders-text
                      :lines="6"
                    ></content-placeholders-text>
                    <content-placeholders-text
                      :lines="6"
                    ></content-placeholders-text>
                  </content-placeholders>
                </div>
              </b-col>
              <b-col sm="12" md="8" class="pl-2 pr-3">
                <div class="sub-card-right rounded">
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-heading />
                    <content-placeholders-text
                      :lines="3"
                    ></content-placeholders-text>
                    <content-placeholders-text
                      :lines="2"
                    ></content-placeholders-text>
                    <content-placeholders-text
                      :lines="2"
                    ></content-placeholders-text>
                    <content-placeholders-text
                      :lines="2"
                    ></content-placeholders-text>
                    <content-placeholders-text
                      :lines="2"
                    ></content-placeholders-text>
                  </content-placeholders>
                </div>
              </b-col>
            </div>
          </template>
          <div class="row" v-if="generateTree">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div class="sub-card-left rounded">
                <div class="solution-hierarchy-content">
                  <div class="offerings-col">
                    <div class="header">
                      <router-link
                        tag="a"
                        :to="{ path: 'retail_services' }"
                        class="solution-hierarchy-link font-weight-bold"
                        :class="getLinkActiveClass('retail')"
                        >Retail Services</router-link
                      >
                    </div>
                    <div class="offerings-bank-services">
                      <LiquorTree
                        :data="retailServices.children"
                        :multiple="false"
                        :options="{ paddingLeft: 10 }"
                      ></LiquorTree>
                    </div>
                  </div>
                  <div class="offerings-col">
                    <div class="header">
                      <router-link
                        tag="a"
                        :to="{ path: 'business_services' }"
                        class="solution-hierarchy-link font-weight-bold"
                        :class="getLinkActiveClass('business')"
                        >Business Services</router-link
                      >
                    </div>
                    <div class="offerings-bank-services">
                      <LiquorTree
                        :data="businessServices.children"
                        :multiple="false"
                        :options="{ paddingLeft: 10 }"
                      ></LiquorTree>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="sub-card-right rounded">
                <div class="solution-hierarchy-expanded">
                  <div class="row mt-2">
                    <b-col md="6">
                      <h6
                        class="pl-2 solution-header font-weight-bold d-inline-block"
                      >
                        {{ getSolution.name }}
                      </h6>
                      <b-btn
                        @click="toggleAllAcord('close')"
                        class="btn-custom mx-1"
                      >
                        <i
                          class="icon-arrow-up icons small small-icon d-inline-block"
                        ></i>
                      </b-btn>
                      <b-btn @click="toggleAllAcord('open')" class="btn-custom">
                        <i
                          class="icon-arrow-down icons small small-icon d-inline-block"
                        ></i>
                      </b-btn>
                    </b-col>
                    <b-col md="2" class="pr-0">
                      <div
                        class="offering-class-wrapper pull-left"
                        style="padding-right: 0.5rem;"
                      >
                        <div
                          class="offering-class"
                          :class="{
                            green:
                              index < greenDotsCount(getSolution.class_value)
                          }"
                          v-for="(count, index) in allLevels"
                          :key="index"
                        >
                          {{ index }}
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      md="4"
                      class="pl-3"
                      v-if="getSolution.most_likely_solutions.length"
                    >
                      <Multiselect
                        track-by="bank_service_id"
                        label="name"
                        :showLabels="false"
                        :value="
                          setValue(
                            getSolution.most_likely_solutions[0],
                            '',
                            'solution'
                          )
                        "
                        :options="getSolution.most_likely_solutions"
                        v-model="solutionSelect"
                        @input="setSelectValue()"
                        class="simple-select analytics-select parent-multiselect"
                      ></Multiselect>
                    </b-col>
                  </div>

                  <div
                    class="row mt-2"
                    v-for="(bankService, sIndex) in getSolution.children"
                    :key="sIndex"
                  >
                    <b-col md="12">
                      <b-card class="custom-card">
                        <b-card-header header-tag="header">
                          <div class="row card-header-row">
                            <b-col md="6">
                              <b-btn
                                class="btn"
                                v-b-toggle="'accordion-' + sIndex"
                              >
                                <span class="when-closed">
                                  <i
                                    class="icon-arrow-down icons small small-icon d-inline-block"
                                  />
                                  <span class="d-inline-block service-name">{{
                                    bankService.name
                                  }}</span>
                                </span>
                                <span class="when-opened">
                                  <i
                                    class="icon-arrow-up icons small small-icon d-inline-block"
                                  />
                                  <span class="d-inline-block service-name">{{
                                    bankService.name
                                  }}</span>
                                </span>
                              </b-btn>
                            </b-col>
                            <b-col md="2" class="pl-3 pr-0">
                              <div class="offering-class-wrapper pull-left">
                                <div
                                  class="offering-class"
                                  :class="{
                                    green:
                                      index <
                                      greenDotsCount(bankService.class_value)
                                  }"
                                  v-for="(count, index) in allLevels"
                                  :key="index"
                                >
                                  {{ index }}
                                </div>
                              </div>
                            </b-col>
                            <b-col
                              md="4"
                              v-if="bankService.most_likely_solutions.length"
                            >
                              <Multiselect
                                track-by="bank_service_id"
                                label="name"
                                :value="
                                  setValue(
                                    bankService.most_likely_solutions[0],
                                    sIndex,
                                    'bank'
                                  )
                                "
                                :showLabels="false"
                                :options="bankService.most_likely_solutions"
                                v-model="bankServiceSelect[sIndex]"
                                @select="setSelectValue()"
                                class="simple-select analytics-select"
                              ></Multiselect>
                            </b-col>
                          </div>
                        </b-card-header>
                        <b-collapse
                          :visible="openAll"
                          :id="'accordion-' + sIndex"
                        >
                          <div class="level-1-child-content">
                            <ul class="level-1-ul offerings-ul">
                              <div
                                class="offerings-li level-1-li"
                                v-for="(bankServiceChild,
                                scIndex) in bankService.children"
                                :key="scIndex"
                              >
                                <div class="row">
                                  <div class="col-md-8">
                                    <div
                                      class="offering-service-container level-1-service font-weight-bold"
                                    >
                                      {{ bankServiceChild.name }}
                                    </div>
                                  </div>
                                  <div class="col-md-4 peer-percentage">
                                    Peer Group % Offering
                                  </div>
                                </div>
                                <ul
                                  class="offerings-ul level_1"
                                  v-for="(bankServiceNestedChild,
                                  scnIndex) in getOfferingUlChildren(
                                    bankServiceChild
                                  )"
                                  :key="scnIndex"
                                >
                                  <li class="offerings-li text-black">
                                    <div class="row offering-service-container">
                                      <div class="service col-md-6 pr-0">
                                        <div class="service-details-info">
                                          <div>
                                            <span
                                              class="has-service"
                                              :class="
                                                getServiceClass(
                                                  bankServiceNestedChild.has_service
                                                )
                                              "
                                            ></span>
                                            {{ bankServiceNestedChild.name }}
                                          </div>
                                          <div
                                            v-if="bankServiceNestedChild.url"
                                            class="solution-text"
                                          >
                                            <a
                                              :href="
                                                formatUrl(
                                                  bankServiceNestedChild.url
                                                )
                                              "
                                              target="_blank"
                                              >View Solution</a
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 peer-table">
                                        <button
                                          type="button"
                                          @click="
                                            showPeerTable(
                                              bankServiceNestedChild,
                                              bankServiceChild.name,
                                              bankServiceNestedChild.name
                                            )
                                          "
                                          :data-bank-service="
                                            bankServiceNestedChild.bank_service_id
                                          "
                                          class="button-link peer-table-link"
                                        >
                                          Peer Table
                                        </button>
                                      </div>
                                      <div class="col-md-4 pl-0 peer-value">
                                        <div
                                          class="d-flex asset-graph-holder pr-1"
                                        >
                                          <div
                                            class="solution-offering-btn"
                                            @click="
                                              showAssetModal(
                                                bankService,
                                                bankServiceNestedChild
                                              )
                                            "
                                          >
                                            <svg class="icon">
                                              <use
                                                xlink:href="#institution-bar-chart"
                                              ></use>
                                            </svg>
                                          </div>
                                        </div>
                                        <div class="progress align-self-center">
                                          <div
                                            class="progress-bar"
                                            :class="
                                              getProgressClass(
                                                bankServiceNestedChild.peer_group_value
                                              )
                                            "
                                            role="progressbar"
                                            :style="
                                              'width: ' +
                                                bankServiceNestedChild.peer_group_value +
                                                '%'
                                            "
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          >
                                            {{
                                              bankServiceNestedChild.peer_group_value
                                            }}%
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </ul>
                          </div>
                        </b-collapse>
                      </b-card>
                    </b-col>
                  </div>
                </div>
              </div>
            </b-col>
          </div>
          <SolutionHierarchyPeerTableModal
            :showModal="showPeerTableModal"
            :reportId="reportId"
            :peerTableSolutionParent="peerTableSolutionParent"
            :peerTableSolutionChild="peerTableSolutionChild"
            :selectedBankService="selectedBankService"
            @closeModal="closeModal"
          >
          </SolutionHierarchyPeerTableModal>
          <AssetGraphModal
            :showModal="showAssetGraphModal"
            :parentService="selectedParentService"
            :bankService="selectedBankService"
            @closeAssetModal="closeAssetModal"
          >
          </AssetGraphModal>
          <bank-service-v-p-modal
            :financialInstituion="selectedInstitution"
          ></bank-service-v-p-modal>
        </v-wait>
      </b-card>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapGetters } from 'vuex'
// mixins
import reportHelper from '../mixins/report_helper'
// ui components
import Multiselect from 'vue-multiselect'
import LiquorTree from 'liquor-tree'
import BankServiceVPModal from '@/modules/online_edits/components/BankServiceVPModal.vue'
import AssetGraphModal from './AssetGraphModal'
import SolutionHierarchyPeerTableModal from './SolutionHierarchyPeerTableModal'
import SolutionHierarchyOfferingModal from './SolutionHierarchyOfferingModal'
import SolutionHierarchyClassificationModal from './SolutionHierarchyClassificationModal'
import SolutionHierarchyNextSolutionModal from './SolutionHierarchyNextSolutionModal'

Vue.use(LiquorTree)

export default {
  name: 'SolutionHierarchy',
  mixins: [reportHelper],
  props: {
    reportId: {
      type: Number,
      required: true
    },
    bankServiceId: {
      type: Number,
      required: true
    }
  },
  components: {
    LiquorTree,
    Multiselect,
    BankServiceVPModal,
    SolutionHierarchyPeerTableModal,
    SolutionHierarchyOfferingModal,
    SolutionHierarchyClassificationModal,
    SolutionHierarchyNextSolutionModal,
    AssetGraphModal
  },
  created() {
    this.fetchServiceData()
  },
  data() {
    return {
      solutionHierarchyData: [],
      themes: [],
      services: [],
      generateTree: false,
      openAll: false,
      peerTableSolutionParent: '',
      peerTableSolutionChild: '',
      bankServiceSelect: [],
      solutionSelect: '',
      selectionTrigger: false,
      allLevels: ['EXCELLENT', 'STRONG', 'SOLID', 'MODERATE', 'TRAILING'],
      selectedParentService: null,
      selectedBankService: null,
      showPeerTableModal: false,
      showAssetGraphModal: false
    }
  },
  computed: {
    ...mapGetters('Advisor', ['setId', 'selectedInstitution']),
    retailServices() {
      return this.services[0]
    },
    businessServices() {
      return this.services[1]
    },
    getSolution() {
      return this.solutionHierarchyData[0]
    }
  },
  methods: {
    getLinkActiveClass(service) {
      let activeClass = 'inactive'
      if (this.bankServiceId == 1 && service == 'retail') activeClass = 'active'
      if (this.bankServiceId == 2 && service == 'business')
        activeClass = 'active'
      return activeClass
    },
    fetchServiceData() {
      this.$wait.start('fetchServiceData')
      this.$http
        .get('/api/reports/' + this.reportId, {
          params: {
            bank_service_id: this.bankServiceId,
            set_id: this.setId
          },
          handleErrors: true
        })
        .then(res => {
          this.services = res.data.bank_services
          this.solutionHierarchyData = res.data.solutions
          this.generateTree = true // this is need temporarily
        })
        .then(() => {
          this.$wait.end('fetchServiceData')
        })
    },
    formatUrl(url) {
      if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        return url
      }
      return 'https://' + url.replace(/"/g, '')
    },
    showAssetModal(bankService, bankServiceNestedChild) {
      this.selectedParentService = bankService
      this.selectedBankService = bankServiceNestedChild
      this.showAssetGraphModal = true
    },
    closeAssetModal() {
      this.showAssetGraphModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-card {
  background-color: #f0f3f5;
  .sub-card-left,
  .sub-card-right {
    font-size: 0.875rem;
    border: solid 1px lightgray;
    background-color: white;
    height: 26rem;
    overflow-y: auto;
    overflow-x: hidden;
    .btn-secondary {
      background-color: #fff;
      border-color: transparent;
      padding: 0.3rem 0rem;
      &.btn-custom {
        padding: 0px 7px;
        background-color: #f7f5f7;
      }
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      box-shadow: none;
    }
    .small-icon {
      font-size: 55%;
      font-weight: bolder;
    }
    .service-name {
      cursor: pointer;
      margin-left: 0.3rem;
      font-size: 0.75rem;
    }
  }
}
.right-padding-zero {
  padding-right: 0px;
}
.left-padding-zero {
  padding-left: 0px;
}
.solution-hierarchy-content {
  font-size: 0.75rem;
  padding: 1rem 0.75rem;
  border-radius: 0.25rem;
  .offerings-bank-services {
    padding: 0.125rem 0 0.25rem 0;
  }
}
.section--heading-offering {
  margin-top: auto;
}
.small-line-icon {
  font-size: 0.8rem;
  padding-left: 0.25rem;
}

/deep/ .tree-arrow.has-child {
  width: 10px;
}
/deep/ .tree-content {
  padding: 0px;
}
/deep/ .tree-arrow {
  margin-left: 5px;
  height: 22px;
}
/deep/ .tree-arrow:not(.has-child) {
  margin-left: 15.2px;
}
/deep/ .tree-arrow.has-child:after {
  left: 6px;
  height: 6px;
  width: 6px;
}
/deep/ .tree-node.selected > .tree-content {
  background-color: #fff;
}
/deep/ .detail-section {
  margin-bottom: 0.5rem;
}
/deep/ .tree-anchor {
  margin-left: 2px;
  line-height: 12px;
  padding: 3px 4px;
}
/deep/ a:hover {
  color: #000;
  text-decoration: none;
}
/deep/ .tree-node > .tree-content:hover {
  background: #fff;
}

.offering-class-text {
  text-align: center;
  font-size: 0.5rem;
  color: #6c6d70;
  text-transform: capitalize;
}
.offering-class-wrapper {
  line-height: 2;
}
.offering-class {
  display: inline-block;
  font-size: 0;
  padding: 0px 0.1rem;
}
.offering-class::before,
.has-service::before {
  background-color: #cfd2d4;
  content: ' ';
  border-radius: 100%;
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
}
.offering-class.green::before,
.has-service.green::before {
  background-color: rgba(141, 196, 74, 0.81);
}

/deep/ .multiselect.simple-select {
  font-size: 0.625rem !important;
}

/deep/ .multiselect {
  min-height: 20px !important;
  right: 1.65rem !important;
  width: 91.5%;
}

/deep/ .analytics-select .multiselect__select {
  height: 25px !important;
}

/deep/ .multiselect__placeholder {
  margin-bottom: 2px !important;
  padding-top: 2px !important;
  font-size: 0.625rem !important;
}

/deep/ .multiselect__tags {
  min-height: 20px !important;
  padding: 2px 8px !important;
  font-size: 0.625rem !important;
  border: 1px solid #aaa !important;
}

/deep/ .multiselect--active {
  min-height: 20px !important;
  font-size: 0.625rem !important;
}

/deep/ .analytics-select .multiselect__tags input {
  font-size: 0.625rem !important;
}

/deep/ .analytics-select .multiselect__tags .multiselect__single {
  width: 95%;
  font-size: 0.625rem !important;
  margin: 0rem;
  padding: 0rem;
}

/deep/ .multiselect.simple-select .multiselect__option--selected {
  font-size: 0.625rem !important;
}

.custom-card {
  margin-bottom: 0rem;
  .card-body {
    padding: 0rem 0.75rem;
  }
  .card-header {
    padding: 0.1rem 0.5rem;
    background-color: #fff;
    border: 1px solid #e5e8ec;
    border-radius: 0.25rem;
  }
  .multiselect {
    right: 0rem !important;
    width: 100%;
  }
}

span.icon-printer {
  cursor: pointer;
}

.card-header-row {
  padding: 0.3rem !important;
  font-size: 0.75rem;
}
.level-1-child-content {
  padding: 0.625rem;
  border: 1px solid #e5e8ec;
  border-top: none;
}
.offerings-ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.level-1-ul .level-1-li {
  border: 1px solid #e5e8ec;
  border-radius: 0.25rem;
  padding: 0.625rem;
  margin-bottom: 0.125rem;
}
.offerings-parent-service .level-1-ul .level-1-service {
  font-weight: bold;
  padding-bottom: 0.25rem;
}
.peer-value,
.view-solution,
.peer-table,
.service {
  padding: 0rem 1rem;
}
.peer-percentage {
  font-size: 0.625rem;
  color: #6c6d70;
  padding-left: 1rem;
}
.offerings-li {
  font-size: 0.6875rem;
}
.text-black {
  color: #4c4d4c;
}
.progress {
  height: 0.75rem;
  margin-bottom: 0;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 94%;
}
.progress-bar {
  font-size: 0.625rem;
  line-height: 1.2;
  background-color: rgba(141, 196, 74, 0.81);
  color: #4c4d4c;
  flex-direction: row;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.has-service {
  display: inline-block;
  margin-right: 0.25rem;
}
.solution-header {
  padding-left: 0.75rem !important;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.parent-multiselect {
  margin-left: 1.1rem;
}
a.inactive {
  color: #343434 !important;
}

.peer-table-link {
  color: #3396b7;
  font-size: 10px;
}
.note {
  margin: 0;
  color: #999;
}
a {
  color: #3396b7;
}

.service-details-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .solution-text {
    max-width: 30%;
    padding-left: 0.3rem;
    font-size: 10px;
  }
}

.peer-value {
  display: flex;
  justify-content: space-between;
}

.asset-graph-holder {
  margin-left: -5px;
}
</style>
