import { render, staticRenderFns } from "./BusinessServices.vue?vue&type=template&id=294bd038&"
import script from "./BusinessServices.vue?vue&type=script&lang=js&"
export * from "./BusinessServices.vue?vue&type=script&lang=js&"
import style0 from "../../../../../scss/_advisor-custom.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./BusinessServices.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports