<template>
  <div class="asset-group">
    <div class="trend-chart-container">
      <div id="column-chart" class="col-chart px-0"></div>
    </div>
    <div class="row chart-legend">
      <div class="col-sm-1"></div>
      <div class="col-sm-9">
        <div class="text-center d-flex justify-content-center">
          <div class="legend-box align-self-center"></div>
          <div class="pl-1">
            {{ fiTypeMapper[selectedInstitution.fi_type] }} Asset Group
          </div>
        </div>
      </div>
      <div class="col-sm-2 pl-0">{{ graphSpan }} {{ period }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Highcharts from 'highcharts'
import Vue from 'vue'
import * as moment from 'moment'

export default {
  name: 'AssetGroupView',
  props: {
    assetData: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    currentPeriod: {
      required: true
    },
    spanId: {
      required: true
    }
  },
  mounted() {
    this.drawChart()
  },
  data() {
    return {
      columnChart: null
    }
  },
  computed: {
    ...mapGetters(['fiTypeMapper', 'spanMapper']),
    ...mapGetters('Advisor', ['selectedInstitution']),
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    assetGroupData() {
      return this.assetData.map(asset => parseFloat(asset.trimmed_mean || 0))
    },
    assetDetail() {
      return this.assetData[0] || {}
    },
    period() {
      return moment(this.currentPeriod).format('MMM-YY')
    },
    graphSpan() {
      return this.spanMapper[this.spanId]
    }
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          this.columnChart.reflow()
        }.bind(this),
        250
      )
    },
    drawChart() {
      let metricFormat = this.assetDetail.format
      let graphData = this.assetData

      let min_of_array = Math.min(...this.assetGroupData)
      let max_of_array = Math.max(...this.assetGroupData)

      this.columnChart = Highcharts.chart('column-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 25
        },
        exporting: {
          scale: 1,
          filename: this.assetDetail.statement_name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download',
              symbolStroke: this.partnerSite ? '#015B7E' : '#666666'
            }
          },
          chartOptions: {
            title: {
              text: this.assetDetail.statement_name,
              style: {
                color: this.partnerSite ? '#015b7e' : '#333333'
              }
            },
            subtitle: {
              text: this.assetDetail.sec_title
            },
            chart: {
              marginTop: 70
            },
            legend: {
              enabled: true,
              verticalAlign: 'bottom',
              squareSymbol: false,
              symbolRadius: 0,
              margin: 15
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                let actualValue = graphData[this.point.index].trimmed_mean
                if (actualValue) {
                  return Vue.filter('formatValue')(this.y, metricFormat)
                } else {
                  return 'NA'
                }
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              }
            },
            pointWidth: 40,
            groupPadding: 0.07,
            pointPadding: 0
          }
        },
        xAxis: {
          categories: this.assetData.map(asset => asset.peer_group_name),
          gridLineWidth: 1,
          labels: {
            y: 16,
            overflow: 'allow',
            style: {
              fontSize: '0.6rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          min: min_of_array - (max_of_array - min_of_array) * 0.1,
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null,
            style: {
              color: this.partnerSite ? '#015b7e' : '#333333'
            }
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatValue')(this.value, metricFormat)
            }
          }
        },
        series: [
          {
            name: `${
              this.fiTypeMapper[this.selectedInstitution.fi_type]
            } Asset Group`,
            data: this.assetGroupData,
            color: this.partnerSite ? '#537CD1' : '#A7CA77'
          }
        ]
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.col-chart {
  height: 15.2rem;
}

.chart-legend {
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica,
    sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.legend-box {
  display: inline-block;
  background-color: #a7ca77;
  width: 1.5rem;
  height: 1.15rem;
}
</style>
