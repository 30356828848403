<template>
  <div class="percentile-container">
    <div class="sub-heading">
      {{ metricDetail.statement_name }}
    </div>
    <div class="percentile-chart pb-1">
      <div class="entries">
        <div class="percentile-header-row">
          <div class="percentile-entries">Metric</div>
          <div class="percentile-entries">Pctle</div>
        </div>
        <div v-if="nationalPercentiles.length">
          <div
            v-for="(nationalPercentile, index) in nationalPercentiles"
            :key="index"
            class="percentile-row"
          >
            <div class="percentile-entries">
              {{ nationalPercentile.value | formatValue(metricDetail.format) }}
            </div>
            <div class="percentile-entries">
              {{ nationalPercentile.percent_id * 100 }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(nationalPercentile, index) in defaultPercentiles"
            :key="index"
            class="percentile-row"
          >
            <div class="percentile-entries">{{ nationalPercentile[0] }}</div>
            <div class="percentile-entries">{{ nationalPercentile[1] }}</div>
          </div>
        </div>
      </div>
      <div class="bar-chart">
        <div class="back"></div>
        <div
          class="front"
          :style="{ height: chartHeight + 'px' }"
          v-if="nationalRank.national_rank"
        >
          <div class="value">
            {{ nationalRank.value | formatValue(metricDetail.format) }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      National Percentile
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentileDistribution',
  props: {
    nationalPercentiles: {
      type: Array,
      required: true
    },
    nationalRank: {
      type: Object,
      required: true
    },
    metricDetail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultPercentiles: [
        ['NA', 99],
        ['NA', 90],
        ['NA', 80],
        ['NA', 70],
        ['NA', 60],
        ['NA', 50],
        ['NA', 40],
        ['NA', 30],
        ['NA', 20],
        ['NA', 10],
        ['NA', 1]
      ]
    }
  },
  computed: {
    chartHeight() {
      let height = (parseFloat(this.nationalRank.national_rank) * 190) / 100
      return Math.min(190, height)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/_advisor-variables';

.percentile-container {
  padding: 1rem 0.75rem;
  padding-top: 0.5rem;

  .sub-heading {
    text-align: center;
    font-family: $proxima_regular;
  }
  .footer {
    padding-top: 0.25rem;
    text-align: center;
    font-size: 0.8125rem;
    font-family: $proxima_regular;
  }
}

.percentile-row,
.percentile-header-row {
  font-size: 0.6875rem;
  color: $text_gray;
  padding: 0.0625rem 0;

  &:after {
    clear: both;
    display: block;
    content: ' ';
  }
}

.percentile-header-row {
  font-family: $proxima_semibold;
  font-weight: 600;
}

.percentile-chart {
  @media (max-width: 991.98px) {
    width: 85%;
  }

  width: 65%;
  margin: 0 auto;
  &:after {
    clear: both;
    display: block;
    content: ' ';
  }
}

.percentile-entries {
  width: 50%;
  float: left;
  text-align: right;
  white-space: nowrap;
}

.entries {
  width: 60%;
  padding-top: 0.25rem;
  float: left;
  text-align: right;
  padding-right: 1rem;
}

.bar-chart {
  width: 40%;
  padding-top: 0.5rem;
  float: left;
  height: 13.5rem;
  position: relative;
  border-bottom: solid 1px $gray;

  .back {
    position: absolute;
    left: 0.75rem;
    right: 0.75rem;
    top: 1.5rem;
    bottom: 0;
    background-color: $gray;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .front {
    position: absolute;
    left: 0.75rem;
    right: 0.75rem;
    bottom: 0;
    background-color: $brand_green;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .value {
    color: #000;
    text-align: center;
    padding: 0.5rem 0;
    font-size: 0.625rem;
    font-family: $proxima_regular;
    font-weight: bold;
  }
}
</style>

<style lang="scss">
@media print {
  .status-check-content {
    .bar-chart {
      width: 30%;
    }
  }
}
</style>
