<template>
  <b-modal
    v-model="showModal"
    id="assetGraphModal"
    @show="fetchAssetGraphData"
    size="lg"
    body-class="pb-0 asset-group"
    footer-class="p-2"
    hide-header
    no-close-on-backdrop
    no-close-on-esc
  >
    <v-wait for="fetchingAssetGraphData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="7"></content-placeholders-text>
        </content-placeholders>
      </template>

      <Highcharts :options="chartOptions"></Highcharts>

      <div class="row chart-legend pb-2">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div class="text-center d-flex justify-content-center">
            <div class="legend-box align-self-center"></div>
            <div class="pl-1">{{ chartTitle }}</div>
          </div>
        </div>
        <div class="col-sm-2">{{ period }}</div>
      </div>
    </v-wait>

    <div slot="modal-footer">
      <div class="row">
        <div class="col-sm-12 text-right">
          <b-button size="" variant="secondary" class="" @click="closeModal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import Vue from 'vue'
import * as moment from 'moment'

export default {
  name: 'AssetGraphModal',
  components: {
    Highcharts: Chart
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
      default: false
    },
    bankService: {
      required: true,
      default: {}
    },
    parentService: {
      required: true,
      default: {}
    }
  },
  data() {
    return {
      assetGroupData: [],
      currentPeriod: null
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters(['fiTypeMapper']),
    ...mapGetters('Advisor', ['selectedInstitution']),
    assetData() {
      return this.assetGroupData.map(asset => parseFloat(asset.value))
    },
    period() {
      return moment(this.currentPeriod).format('MMMM YYYY')
    },
    chartTitle() {
      return `${
        this.fiTypeMapper[this.selectedInstitution.fi_type]
      } Asset Group`
    },
    chartOptions() {
      if (this.parentService && this.bankService) {
        let graphData = this.assetGroupData
        let min_of_array = Math.min(...this.assetData)
        let max_of_array = Math.max(...this.assetData)

        return {
          credits: {
            enabled: false
          },
          chart: {
            type: 'column',
            marginTop: 70
          },
          exporting: {
            scale: 1,
            filename: this.parentService.name,
            chartOptions: {
              legend: {
                enabled: true,
                verticalAlign: 'bottom',
                squareSymbol: false,
                symbolRadius: 0,
                margin: 15
              }
            },
            buttons: {
              contextButton: {
                menuItems: [
                  'downloadPNG',
                  'downloadJPEG',
                  'downloadPDF',
                  'downloadCSV'
                ],
                y: -11,
                symbol: 'download',
                symbolStroke: this.partnerSite ? '#015B7E' : '#666666'
              }
            }
          },
          title: {
            text: this.parentService.name,
            style: {
              color: this.partnerSite ? '#015B7E' : '#333333'
            }
          },
          subtitle: {
            text: this.bankService.name
          },
          tooltip: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              animation: false,
              dataLabels: {
                enabled: true,
                formatter: function() {
                  let actualValue = graphData[this.point.index].value
                  if (actualValue) {
                    return Vue.filter('formatValue')(this.y, 'P2')
                  } else {
                    return 'NA'
                  }
                },
                style: {
                  fontSize: '11px',
                  color: '#666666',
                  fontWeight: 'normal'
                },
                crop: false,
                overflow: 'none'
              },
              pointWidth: 40,
              groupPadding: 0.07,
              pointPadding: 0
            }
          },
          xAxis: {
            categories: this.assetGroupData.map(asset => asset.peer_group_name),
            gridLineWidth: 0,
            labels: {
              y: 16,
              style: {
                fontSize: '0.8rem',
                fontWeight: 'normal'
              }
            }
          },
          yAxis: {
            min: min_of_array - (max_of_array - min_of_array) * 0.1,
            maxPadding: 0.1,
            minPadding: 0.1,
            gridLineWidth: 1,
            lineWidth: 1,
            plotLines: [
              {
                color: '#e6e6e6',
                width: 1,
                value: 0
              }
            ],
            title: {
              text: null
            },
            labels: {
              formatter: function() {
                return Vue.filter('formatValue')(this.value, 'P2')
              }
            }
          },
          series: [
            {
              name: this.chartTitle,
              data: this.assetData,
              color: this.partnerSite ? '#537CD1' : '#A7CA77'
            }
          ]
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    fetchAssetGraphData() {
      this.$wait.start('fetchingAssetGraphData')
      this.$http
        .get(
          '/api/industry_distributions/' +
            this.bankService.bank_service_id +
            '/bank_service',
          {
            params: {
              fi_type: this.selectedInstitution.fi_type
            },
            handleErrors: true
          }
        )
        .then(res => {
          this.assetGroupData = res.data.chart_data
          this.currentPeriod = res.data.current_period
        })
        .finally(() => {
          this.$wait.end('fetchingAssetGraphData')
        })
    },
    closeModal() {
      this.$emit('closeAssetModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-legend {
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica,
    sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.legend-box {
  display: inline-block;
  background-color: #a7ca77;
  width: 1.5rem;
  height: 0.75rem;
}
</style>
