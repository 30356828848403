<template>
  <b-modal
    id="bankServiceVPModal"
    modal-class="bank-service-v-p-modal"
    size="xl"
    @shown="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <v-wait for="loadingBankServices">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <b-modal
        id="serviceUpdateInstructions"
        size="lg"
        title="Submit a Service Update"
        ok-only
      >
        <p>
          Thank you for your input regarding the products, services, channels,
          and technologies being deployed by this financial institution. Below
          is a quick guide on how to use this form.
        </p>
        <h6 class="font-weight-bold mb-1">1. Find a Service</h6>
        <p>
          Using the left-hand menu, click the <b>Technographic Category</b> to
          display the detailed list of related services.
        </p>
        <h6 class="font-weight-bold">2. Update the Service</h6>
        <p>
          There are 3 data items you can update:
        </p>
        <ul class="service-options-list">
          <li>
            <b>Has Service:</b> Use the checkbox to indicate if the FI has
            <span>
              <input type="checkbox" checked="checked" class="ml-1" />
            </span>
            this service or not.
          </li>
          <li>
            <b>Vendor:</b> (Optional) Use the drop-down to select the Vendor
            being used to deliver the service.
          </li>
          <li>
            <b>Product:</b> (Required, if Vendor selected) Use the drop-down to
            select the Product being used by the FI.
          </li>
        </ul>
        <ul class="service-options-note">
          <li>
            NOTE: You can edit multiple services at one time.
          </li>
        </ul>
        <h6 class="font-weight-bold">3. New Vendor or Product</h6>
        <p>
          If the Vendor or Product is not in the drop-down list, use the [+] to
          add a new Vendor or Product.
        </p>
        <h6 class="font-weight-bold">4. Submit Service Update</h6>
        <p>
          Click the blue <b>Submit Update</b> button to save your update. Your
          update will be reviewed and applied when approved.
        </p>
      </b-modal>

      <b-card
        class="std-border reports-card"
        body-class=""
        header-class="pl-3 py-1"
      >
        <div slot="header">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="pr-2 cui-terminal"></span>
              <h6 class="mb-0"><b>Technographics</b></h6>
            </div>
            <div>
              <div class="d-flex align-items-center">
                <header-logo
                  logoType="fi"
                  :logoUrl="s3LogoUrl"
                  :logoId="financialInstituion.id"
                ></header-logo>
                <div>
                  <h5 class="mb-0">{{ financialInstituion.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters technographics-segment pt-1">
          <div class="col-sm-3">
            <h6 class="">Technographics Categories</h6>
            <div
              class="card std-border mb-0 technographics-menu tree-menu segment-column"
            >
              <div class="card-body p-0">
                <div
                  v-for="(serviceGroup, index) in treeData"
                  :key="index"
                  class="pb-3"
                >
                  <div class="font-weight-bold text-uppercase px-2 pt-2">
                    {{ serviceGroup.text }}
                  </div>
                  <ul class="list-group">
                    <li
                      :class="[
                        {
                          active: selectedService.data === childService.data
                        },
                        'list-group-item pl-4'
                      ]"
                      v-for="(childService, index) in serviceGroup.children"
                      :key="index"
                      @click="selectService(childService, serviceGroup.text)"
                    >
                      {{ childService.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div :class="['col-sm-9', 'px-2 details-holder']">
            <h6 class="" v-if="selectedParent">
              {{ selectedParent }} : {{ selectedService.text }}
            </h6>
            <div class="card std-border mb-0 techno-details segment-column">
              <div class="card-body p-1">
                <div class="row p-3">
                  <div
                    class="offset-sm-4 col-sm-2 text-right align-self-center"
                  >
                    <h6 class="mb-0">Search</h6>
                  </div>

                  <div class="col-sm-6 pl-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type text to search..."
                      v-model="searchText"
                    />
                  </div>
                </div>

                <div class="row my-2 no-gutters">
                  <div class="col-sm-5 text-center"><h6>Has Service</h6></div>
                  <div class="col-sm-7">
                    <div class="row">
                      <div class="col-sm-6 text-center">
                        <h6>Vendor</h6>
                      </div>
                      <div class="col-sm-6 text-center">
                        <h6>Product</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <LiquorTree
                  class="bs-vp-tree"
                  :data="treeData"
                  :multiple="false"
                  :showChildren="true"
                  :parentSelect="false"
                  :options="treeOptions"
                  :filter="searchText"
                  ref="solutionTree"
                  @node:selected="nodeSelected"
                  @node:checked="nodeChecked"
                  @node:unchecked="nodeUnChecked"
                >
                  <div
                    :class="[
                      node.data.bs_vendor_details.length ? '' : 'py-1',
                      'tree-text d-flex justify-content-between align-items-center'
                    ]"
                    slot-scope="{ node }"
                  >
                    <template>
                      <span :id="'tg-' + node.id">
                        {{ node.text }}
                      </span>
                      <b-popover
                        :target="'tg-' + node.id"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="node.data.description"
                      >
                        {{ node.data.description }}
                      </b-popover>
                      <div
                        :class="[
                          node.data.has_service ? '' : 'tag-disabled',
                          'text-left'
                        ]"
                      >
                        <div
                          class="d-flex"
                          v-if="node.data.bs_vendor_details.length"
                        >
                          <Multiselect
                            track-by="id"
                            label="name"
                            v-model="node.data.selected_vendor"
                            :allowEmpty="false"
                            :showLabels="false"
                            :options="node.data.bs_vendor_details"
                            class="simple-select analytics-select vp-select my-1"
                            :disabled="!node.data.bs_vendor_details.length"
                            @select="vendorChange($event, node)"
                          ></Multiselect>
                          <span
                            class="fa fa-plus-square-o align-self-center pl-1 pr-4"
                            title="Add New Vendor/Product"
                            @click="showNewVendorModal(node)"
                          ></span>
                          <Multiselect
                            track-by="id"
                            label="name"
                            v-model="node.data.selected_product"
                            :allowEmpty="false"
                            :showLabels="false"
                            :options="vendorProducts(node)"
                            class="simple-select analytics-select vp-select my-1"
                            :disabled="!node.data.bs_vendor_details.length"
                            @select="productChange($event, node)"
                          >
                            <template slot="noOptions" slot-scope="{}"
                              ><span>{{
                                node.data.selected_vendor &&
                                node.data.selected_vendor.id
                                  ? 'List is empty.'
                                  : 'Select Vendor to update.'
                              }}</span></template
                            >
                          </Multiselect>
                          <span
                            class="fa fa-plus-square-o align-self-center pl-1 pr-3"
                            title="Add New Vendor/Product"
                            @click="showNewProductModal(node)"
                          ></span>
                        </div>
                      </div>
                      <b-popover
                        :target="'tg-' + node.id"
                        triggers="hover"
                        placement="right"
                        v-if="node.description"
                      >
                        {{ node.description }}
                      </b-popover>
                    </template>
                  </div>
                </LiquorTree>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="px-1">
              <button
                type="button"
                class="btn btn-secondary"
                @click="$bvModal.hide('bankServiceVPModal')"
              >
                Cancel
              </button>
            </div>
            <div class="px-1">
              <button type="button" class="btn btn-primary" @click="updateBDI">
                Submit Update
              </button>
            </div>
          </div>
        </div>

        <b-modal
          id="addNewVendorModal"
          :title="`New Vendor/Product: ${selectedBankService.name}`"
          size="lg"
          hide-footer
        >
          <div>
            <div class="row align-items-center mb-3">
              <div class="col-sm-3">
                <h6 class="d-flex flex-column mb-0">
                  Vendor Name:
                  <span class="text-muted">
                    <small>(required)</small>
                  </span>
                </h6>
              </div>
              <div class="col-sm-6">
                <b-input-group class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="newVendor"
                    autocomplete="off"
                    :class="{
                      'is-invalid': $v.newVendor.$error,
                      'is-valid': !$v.newVendor.$invalid
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.newVendor.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
              </div>
            </div>
            <div class="row align-items-center mb-3">
              <div class="col-sm-3">
                <h6 class="d-flex flex-column mb-0">
                  Product Name:
                  <span class="text-muted">
                    <small>(required)</small>
                  </span>
                </h6>
              </div>
              <div class="col-sm-6">
                <b-input-group class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="newProduct"
                    autocomplete="off"
                    :class="{
                      'is-invalid': $v.newProduct.$error,
                      'is-valid': !$v.newProduct.$invalid
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.newProduct.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
              </div>
            </div>
            <div class="row align-items-center mb-3">
              <div class="col-sm-3">
                <h6 class="d-flex flex-column mb-0">
                  Vendor Website:
                  <span class="text-muted">
                    <small>(required)</small>
                  </span>
                </h6>
              </div>
              <div class="col-sm-6">
                <b-input-group class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="newVendorWebsite"
                    autocomplete="off"
                    :class="{
                      'is-invalid': $v.newVendorWebsite.$error,
                      'is-valid': !$v.newVendorWebsite.$invalid
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.newVendorWebsite.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 my-3 d-flex justify-content-center">
                <div class="px-1">
                  <button
                    type="button"
                    class="btn fw-btn btn-secondary"
                    @click="$bvModal.hide('addNewVendorModal')"
                  >
                    Cancel
                  </button>
                </div>
                <div class="px-1">
                  <button
                    type="button"
                    class="btn fw-btn btn-primary"
                    @click="addNewVendor"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal
          id="addNewProductModal"
          :title="`New Product: ${selectedBankService.name}`"
          size="lg"
          hide-footer
        >
          <div>
            <div class="row align-items-center mb-3">
              <div class="col-sm-3">
                <h6 class="d-flex flex-column mb-0">
                  Vendor Name:
                  <span class="text-muted">
                    <small>(required)</small>
                  </span>
                </h6>
              </div>
              <div class="col-sm-6">
                <b-input-group class="mb-0">
                  <b-form-select
                    v-model="newVendor"
                    :options="this.selectedBankService.bs_vendor_details"
                    :class="{
                      'is-invalid': $v.newVendor.$error,
                      'is-valid': !$v.newVendor.$invalid
                    }"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                  <b-form-invalid-feedback v-if="!$v.newVendor.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
              </div>
            </div>
            <div class="row align-items-center mb-3">
              <div class="col-sm-3">
                <h6 class="d-flex flex-column mb-0">
                  Product Name:
                  <span class="text-muted">
                    <small>(required)</small>
                  </span>
                </h6>
              </div>
              <div class="col-sm-6">
                <b-input-group class="mb-0">
                  <b-form-input
                    type="text"
                    v-model="newProduct"
                    autocomplete="off"
                    :class="{
                      'is-invalid': $v.newProduct.$error,
                      'is-valid': !$v.newProduct.$invalid
                    }"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.newProduct.required"
                    >can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 my-3 d-flex justify-content-center">
                <div class="px-1">
                  <button
                    type="button"
                    class="btn fw-btn btn-secondary"
                    @click="$bvModal.hide('addNewProductModal')"
                  >
                    Cancel
                  </button>
                </div>
                <div class="px-1">
                  <button
                    type="button"
                    class="btn fw-btn btn-primary"
                    @click="addNewProduct"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </b-card>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
//api
import bankServicesAPI from '@/api/finapps/bank_services'
import userEditsAPI from '@/api/finapps/user_edits'
// ui components
import LiquorTree from 'liquor-tree'
import Multiselect from 'vue-multiselect'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
// validations
import { required } from 'vuelidate/lib/validators'
// utilities
import deepClone from '@/utilities.js'

export default {
  name: 'BankServiceVPModal',
  components: {
    LiquorTree,
    Multiselect,
    HeaderLogo
  },
  props: {
    financialInstituion: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
      treeData: null,
      selectedParent: null,
      selectedService: {},
      selectedBankService: {},
      selectedVendor: {},
      newVendor: null,
      newProduct: null,
      newVendorWebsite: null,
      selectedNode: null,
      searchText: null,
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: true,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      changedServices: [],
      vpBankServices: [],
      originalVPBankServices: []
    }
  },
  computed: {
    ...mapState('OnlineEdits', {
      showInstructions: state => state.showInstructions
    }),
    ...mapGetters('Advisor', ['s3LogoUrl']),
    canAddNewVendor() {
      return !(
        this.$v.newVendor.$invalid ||
        this.$v.newProduct.$invalid ||
        this.$v.newVendorWebsite.$invalid
      )
    },
    canAddNewProduct() {
      return !(this.$v.newVendor.$invalid || this.$v.newProduct.$invalid)
    }
  },
  methods: {
    ...mapMutations('OnlineEdits', ['setShowInstructions']),
    loadInitData() {
      return this.getBankServices().then(() => {
        this.treeData = this.convertToHierarichalData(this.vpBankServices)
        this.setDefaultService()
        this.showInstructionsModal()
      })
    },
    getBankServices() {
      this.$wait.start('loadingBankServices')

      return bankServicesAPI
        .bdiTreeData(this.financialInstituion.id)
        .then(res => {
          this.vpBankServices = deepClone(res.bank_services)
          this.originalVPBankServices = deepClone(res.bank_services)
        })
        .finally(() => {
          this.$wait.end('loadingBankServices')
        })
    },
    showInstructionsModal() {
      if (this.showInstructions) {
        this.$bvModal.show('serviceUpdateInstructions')

        this.setShowInstructions(false)
      }
    },
    convertToHierarichalData(services = [], id = null, link = 'parent_id') {
      return services
        .filter(service =>
          id === null
            ? !services.some(s => s.id === service[link])
            : service[link] === id
        )
        .map(service => {
          service.selected_vendor =
            service.bs_vendor_details.find(vd => vd.id === service.vendor_id) ||
            null

          service.selected_product = service.selected_vendor
            ? service.selected_vendor.products.find(
                p => p.id === service.product_id
              )
            : null

          return {
            id: service.id,
            text: service.name,
            data: service,
            state: {
              expanded: true,
              checked: service.has_service
            },
            children: this.convertToHierarichalData(services, service.id)
          }
        })
    },
    setDefaultService() {
      let defaultService = this.treeData[0]
      this.selectedParent = defaultService.text
      this.selectedService = defaultService.children[0]
      this.$refs.solutionTree.setModel(this.selectedService.children)
    },
    userEditedServices() {
      return this.vpBankServices.filter(vpbs => vpbs.edited)
    },
    selectService(service, parentName) {
      let childServices = this.convertToHierarichalData(
        this.vpBankServices,
        service.id
      )

      this.searchText = null
      this.selectedParent = parentName
      this.selectedService = service
      this.$refs.solutionTree.setModel(
        childServices.length
          ? childServices
          : [
              {
                id: service.id,
                text: service.text,
                data: service.data,
                state: {
                  expanded: true,
                  checked: service.data.has_service
                },
                children: []
              }
            ]
      )
    },
    nodeSelected(node) {
      this.selectedNode = node
    },
    getMatchingService(node) {
      return this.vpBankServices.find(cbs => parseInt(cbs.id) === node.data.id)
    },
    nodeChecked(node) {
      let matchingService = this.getMatchingService(node)

      matchingService.has_service = true
      matchingService.edited = true

      node.data.has_service = true

      if (node.data.vendor_id) {
        node.data.selected_vendor =
          node.data.bs_vendor_details.find(
            vd => vd.id === node.data.vendor_id
          ) || null

        node.data.selected_product = node.data.selected_vendor
          ? node.data.selected_vendor.products.find(
              p => p.id === node.data.product_id
            )
          : null

        matchingService.selected_vendor = node.data.selected_vendor
        matchingService.selected_product = node.data.selected_product
      }
    },
    nodeUnChecked(node) {
      let matchingService = this.getMatchingService(node)

      matchingService.has_service = false
      matchingService.edited = true

      matchingService.selected_vendor = null
      matchingService.selected_product = null

      node.data.selected_vendor = null
      node.data.selected_product = null
      node.data.has_service = false
    },
    vendorChange(selectedVendor, node) {
      let matchingService = this.getMatchingService(node)

      matchingService.edited = true
      matchingService.vendor_id = selectedVendor.id
      matchingService.selected_vendor = selectedVendor

      node.data.selected_product = null
    },
    productChange(selectedProduct, node) {
      let matchingService = this.getMatchingService(node)

      matchingService.edited = true
      matchingService.product_id = selectedProduct.id
      matchingService.selected_product = selectedProduct
    },
    validChangedServices() {
      return this.userEditedServices().filter(editedService => {
        let originalService = this.originalVPBankServices.find(
          ovpbs => ovpbs.id === editedService.id
        )

        return (
          this.isServiceChanged(originalService, editedService) ||
          this.isVendorChanged(originalService, editedService) ||
          this.isProductChanged(originalService, editedService)
        )
      })
    },
    isServiceChanged(originalService, editedService) {
      return originalService.has_service !== editedService.has_service
    },
    isVendorChanged(originalService, editedService) {
      let changedVendorID = editedService.selected_vendor
        ? editedService.selected_vendor.id
        : null

      return originalService.vendor_id !== changedVendorID
    },
    isProductChanged(originalService, editedService) {
      let changedProductID = editedService.selected_product
        ? editedService.selected_product.id
        : null

      return originalService.product_id !== changedProductID
    },
    updateBDI() {
      if (
        this.userEditedServices().length &&
        this.validChangedServices().length
      ) {
        let updatedServices = this.validChangedServices().map(
          changedService => {
            return {
              bank_service_id: changedService.id,
              has_service: changedService.has_service,
              vendor_id: (changedService.selected_vendor || {}).id,
              product_id: (changedService.selected_product || {}).id
            }
          }
        )

        userEditsAPI
          .updateBDI(this.financialInstituion.id, updatedServices)
          .then(() => {
            this.$toasted.global.action_success('Changes updated successfully.')
            this.$bvModal.hide('bankServiceVPModal')
            this.$emit('bdiDataUpdated')
          })
      } else {
        this.$toasted.global.invalid(
          'No changes on BDI, please edit before submit.'
        )
      }
    },
    vendorProducts(node) {
      return node.data.selected_vendor ? node.data.selected_vendor.products : []
    },
    showNewVendorModal(node) {
      this.$v.$reset()
      this.resetNewVendorState()

      this.selectedBankService = node.data
      this.$bvModal.show('addNewVendorModal')
    },
    showNewProductModal(node) {
      this.$v.$reset()
      this.resetNewVendorState()

      this.selectedBankService = node.data
      this.newVendor = this.selectedBankService.selected_vendor
        ? this.selectedBankService.selected_vendor.id
        : null

      this.$bvModal.show('addNewProductModal')
    },
    resetNewVendorState() {
      this.newVendor = null
      this.newProduct = null
      this.newVendorWebsite = null
    },
    addNewVendor() {
      this.$v.newVendor.$touch()
      this.$v.newProduct.$touch()
      this.$v.newVendorWebsite.$touch()

      if (this.canAddNewVendor) {
        userEditsAPI
          .createVendor(
            this.financialInstituion.id,
            this.selectedBankService.id,
            {
              vendor_name: this.newVendor,
              product_name: this.newProduct,
              vendor_url: this.newVendorWebsite
            }
          )
          .then(() => {
            this.$toasted.global.action_success(
              'New Vendor successfully added for review.'
            )
            this.$bvModal.hide('addNewVendorModal')
          })
      }
    },
    addNewProduct() {
      this.$v.newVendor.$touch()
      this.$v.newProduct.$touch()

      if (this.canAddNewProduct) {
        userEditsAPI
          .createProduct(
            this.financialInstituion.id,
            this.selectedBankService.id,
            {
              vendor_id: this.newVendor,
              product_name: this.newProduct
            }
          )
          .then(() => {
            this.$toasted.global.action_success(
              'New Product successfully added for review.'
            )
            this.$bvModal.hide('addNewProductModal')
          })
      }
    }
  },
  validations: {
    newProduct: {
      required
    },
    newVendor: {
      required
    },
    newVendorWebsite: {
      required
    }
  }
}
</script>

<style lang="scss" src="../scss/online.edits.scss"></style>

<style lang="scss">
.bank-service-v-p-modal {
  .tree {
    span {
      &.tree-anchor {
        display: block;
      }
    }
  }

  .vp-select {
    width: 11rem;

    &.multiselect--disabled {
      background: initial;
    }
  }

  .simple-select {
    min-height: 30px;

    .multiselect__tags {
      font-size: 13px;

      .multiselect__placeholder {
        margin-bottom: 0;
        margin-left: 4px;
      }
    }
  }
}

.add-vendor-product {
  .card {
    .card-header {
      padding-top: 1.3rem;

      .nav-tabs {
        .nav-link.active {
          border-top: solid 1px #a4b7c1;
        }
      }
    }
  }
}

.service-options-list {
  list-style: lower-alpha;
}

.service-options-note {
  padding-inline-start: 20px;

  li {
    list-style: none;
  }
}
</style>
