<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-1 sub-card"
        header-class="px-2 py-1 pt-2"
      >
        <div slot="header">
          <div class="row ml-0 mr-0">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div class="row">
                <b-col md="8" class="pl-2">
                  <div>
                    <h2 class="section--heading text-uppercase">
                      STATUS CHECK
                      <i
                        class="icon-info icons small-line-icon"
                        v-b-modal.sc-info
                      ></i>
                    </h2>
                  </div>
                  <h5 class="section--heading-note mb-0">Where do we stand?</h5>
                  <b-modal
                    size="lg"
                    id="sc-info"
                    class="advisor-info-modal"
                    title="Status Check"
                    ok-only
                  >
                    <div v-if="isSelectedCreditUnion">
                      <p>
                        The Status Check addresses “where do we stand” for the
                        subject institution through two views: Performance
                        Status or Percentile Distribution. The Performance
                        Status arc displays the bank performance level as well
                        as classification direction and breakpoints relative to
                        the <b><i class="black-text">selected peer group</i></b
                        >. The metric differential, annual cost/benefit and
                        trend are also displayed for the measurement period.
                      </p>
                      <p>
                        The Percentile Distribution view provides national
                        distribution for the metric and displays the subject
                        institution’s current
                        <b><i class="black-text">national</i></b> percentile
                        rank.
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        The Status Check addresses “where do we stand” for the
                        subject institution through two views: Performance
                        Status or Percentile Distribution. The Performance
                        Status arc displays the bank performance level as well
                        as classification direction and breakpoints relative to
                        the <b><i class="black-text">selected peer group</i></b
                        >. The metric differential, annual cost/benefit and
                        trend are also displayed for the measurement period.
                      </p>
                      <p>
                        The Percentile Distribution view provides national
                        distribution for the metric and displays the subject
                        institution’s current
                        <b><i class="black-text">national</i></b> percentile
                        rank.
                      </p>
                    </div>
                  </b-modal>
                </b-col>
                <b-col
                  md="4"
                  class="d-flex justify-content-end align-items-end"
                >
                  <div
                    class="advisor-btn"
                    @click="updateChartType('performance')"
                    title="Performance Status"
                  >
                    <svg class="icon">
                      <use
                        xlink:href="#speedometer"
                        :class="{ active: isPerformanceChart }"
                      ></use>
                    </svg>
                  </div>
                  <div
                    class="advisor-btn"
                    @click="updateChartType('distribution')"
                    title="Percentile Distribution"
                  >
                    <svg class="icon">
                      <use
                        xlink:href="#percentage"
                        :class="{ active: isDistributionChart }"
                      ></use>
                    </svg>
                  </div>
                </b-col>
              </div>
            </b-col>
            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="row">
                <b-col md="6">
                  <div v-if="!metricDetail">
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading></content-placeholders-heading>
                    </content-placeholders>
                  </div>
                  <div v-else>
                    <div>
                      <h2 class="section--heading">
                        {{ metricDetail.statement_name }}
                      </h2>
                    </div>
                    <h5 class="section--heading-note mb-0">
                      {{ metricDetail.sec_title }}
                    </h5>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  class="ext-icon-container px-0 justify-content-end"
                >
                  <div class="d-flex" v-if="mergerEnabled">
                    <div
                      class="advisor-btn mr-4"
                      @click="updateSetId"
                      title="Merger Restated"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#merger"
                          :class="{ active: setId === 2 }"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div
                      class="advisor-btn"
                      @click="updateSpan(3)"
                      title="Yearly"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#yearly"
                          :class="{ active: spanId === 3 }"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="advisor-btn"
                      @click="updateSpan(1)"
                      title="Quarterly"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#quarterly"
                          :class="{ active: spanId === 1 }"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="advisor-btn mr-1"
                      @click="updateSpan(2)"
                      title="Trailing 12"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#trailing-12"
                          :class="{ active: spanId === 2 }"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div class="d-flex pl-2">
                    <div
                      class="advisor-btn mr-1"
                      @click="showPeers"
                      :title="peerGroupTooltip"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#popup-table"
                          :class="{ 'disabled-icon': !hasValidPeerData }"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="advisor-btn"
                      @click="updateStatusDisplay('table')"
                      title="Metrics View"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#percentage"
                          :class="{ active: isTableDisplay }"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="advisor-btn"
                      @click="updateStatusDisplay('chart')"
                      title="Chart View"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#bar-chart"
                          :class="{ active: isChartDisplay }"
                        ></use>
                      </svg>
                    </div>
                    <div
                      class="advisor-btn"
                      @click="updateStatusDisplay('assetGraph')"
                      title="Peer Group Distribution"
                    >
                      <svg class="icon">
                        <use
                          xlink:href="#institution-bar-chart"
                          :class="{ active: isAssetGraphDisplay }"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </b-col>
              </div>
            </b-col>
          </div>
        </div>
        <div class="row">
          <b-col sm="12" md="4" class="pl-3 pr-2">
            <div class="sub-card-left rounded">
              <v-wait for="fetchingStatusData">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="11"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div class="status-check-content">
                  <component
                    v-bind:is="selectedStatusComponent"
                    :breakPoints="breakPoints"
                    :metricDetail="metricDetail"
                    :reportDetail="reportDetail"
                    :nationalPercentiles="nationalPercentiles"
                    :nationalRank="nationalRank"
                  ></component>
                </div>
              </v-wait>
            </div>
          </b-col>
          <b-col sm="12" md="8" class="px-2">
            <div class="sub-card-right rounded">
              <v-wait for="fetchingStatusData">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="11"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div class="metric-details">
                  <component
                    v-bind:is="selectedViewComponent"
                    :nationalRank="nationalRank"
                    :peerGroupNationalRank="peerGroupNationalRank"
                    :metricDetail="metricDetail"
                    :metricHistory="metricHistory"
                    :assetData="assetGroupData"
                    :currentPeriod="currentPeriod"
                    :spanId="spanId"
                    :viewablePeerGroups="viewablePeerGroups"
                    @peerGroupUpdated="peerGroupUpdated"
                  ></component>
                </div>
              </v-wait>
            </div>
          </b-col>
        </div>
      </b-card>
    </div>
    <PeerGroupModal
      :show="showPeerModal"
      :metricId="metricId"
      :primaryTitle="metricDetail.statement_name"
      :secondaryTitle="metricDetail.sec_title"
      @closeModal="closeModal"
      v-if="hasValidPeerData"
    ></PeerGroupModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import PeerGroupModal from '../components/PeerGroupModal'
import PercentileDistribution from '../components/PercentileDistribution'
import PerformanceStatus from '../components/PerformanceStatus'
import MetricsView from '../components/MetricsView'
import ChartView from '../components/ChartView'
import AssetGroupView from '../components/AssetGroupView'

export default {
  name: 'StatusCheck',
  components: {
    PeerGroupModal,
    PercentileDistribution,
    PerformanceStatus,
    MetricsView,
    ChartView,
    AssetGroupView
  },
  props: {
    metricId: {
      required: true
    }
  },
  created() {
    this.fetchStatusData()
  },
  data() {
    return {
      metricDetail: {},
      nationalRank: null,
      breakPoints: [],
      metricHistory: [],
      nationalPercentiles: [],
      peerGroupNationalRank: null,
      reportDetail: null,
      viewablePeerGroups: [],
      peerHeaderFields: null,
      showPeerModal: false,
      assetGroupData: [],
      currentPeriod: null
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'spanId',
      'setId',
      'reportId',
      'chartType',
      'statusDisplay',
      'isSelectedCreditUnion',
      'selectedInstitution'
    ]),
    isPerformanceChart() {
      return this.chartType === 'performance'
    },
    isDistributionChart() {
      return this.chartType === 'distribution'
    },
    isTableDisplay() {
      return this.statusDisplay === 'table'
    },
    isChartDisplay() {
      return this.statusDisplay === 'chart'
    },
    isAssetGraphDisplay() {
      return this.statusDisplay === 'assetGraph'
    },
    selectedStatusComponent() {
      return this.isPerformanceChart
        ? 'PerformanceStatus'
        : 'PercentileDistribution'
    },
    selectedViewComponent() {
      if (this.isAssetGraphDisplay) {
        return 'AssetGroupView'
      } else if (this.isChartDisplay) {
        return 'ChartView'
      } else {
        return 'MetricsView'
      }
    },
    peerGroupTooltip() {
      if (!this.hasValidPeerData) {
        return 'Score data not available for individual peers'
      } else {
        return 'Peer Group Metrics'
      }
    },
    hasValidPeerData() {
      return !this.metricDetail.is_score_metric
    },
    mergerEnabled() {
      return this.selectedInstitution.fi_type === 'bank'
    }
  },
  methods: {
    ...mapMutations('Advisor', [
      'setSpanId',
      'toggleSet',
      'setChartType',
      'setStatusDisplay'
    ]),
    fetchStatusData() {
      this.$wait.start('fetchingStatusData')
      Promise.all([this.fetchMetricData(), this.fetchAssetGroupData()]).then(
        () => {
          this.$wait.end('fetchingStatusData')
        }
      )
    },
    fetchMetricData() {
      return this.$http
        .get('/api/metrics/' + this.metricId + '/status_check', {
          params: {
            report_id: this.reportId,
            metric_id: this.metricId,
            span_id: this.spanId,
            set_id: this.setId
          },
          handleErrors: true
        })
        .then(res => {
          this.metricDetail = res.data.metric_details
          this.nationalPercentiles = res.data.national_percentiles
          this.nationalRank = res.data.bank_national_rank[0] || {}
          this.peerGroupNationalRank = res.data.peer_group_national_rank[0]
          this.breakPoints = res.data.breakpoints
          this.metricHistory = res.data.metric_history
          this.viewablePeerGroups = res.data.viewable_peer_groups
          this.reportDetail = res.data.report_details
        })
        .then(() => {})
    },
    fetchAssetGroupData() {
      return this.$http
        .get('/api/industry_distributions/' + this.metricId + '/metric', {
          params: {
            fi_type: this.selectedInstitution.fi_type,
            span_id: this.spanId,
            set_id: this.setId
          },
          handleErrors: true
        })
        .then(res => {
          this.currentPeriod = res.data.current_period
          this.assetGroupData = res.data.chart_data
        })
        .then(() => {})
    },
    updateSpan(spanId) {
      this.setSpanId(spanId)
      this.fetchStatusData()
    },
    updateSetId() {
      this.toggleSet()
      this.fetchStatusData()
      this.$emit('mergerRestateChanged')
    },
    updateChartType(type) {
      this.setChartType(type)
    },
    updateStatusDisplay(type) {
      this.setStatusDisplay(type)
    },
    peerGroupUpdated() {
      this.$emit('peerGroupUpdated')
    },
    showPeers() {
      if (this.hasValidPeerData) {
        this.showPeerModal = true
      }
    },
    closeModal() {
      this.showPeerModal = false
    }
  },
  watch: {
    metricId: function() {
      this.fetchStatusData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/_advisor-variables';

.ext-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.sub-card {
  background-color: #f0f3f5;
  .sub-card-left,
  .sub-card-right {
    border: solid 1px lightgray;
    background-color: white;
    height: 18rem;
  }
}

.metric-details {
  padding: 1rem 0.75rem;
  font-size: 0.8125rem;
}

.disabled-icon {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
