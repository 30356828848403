<template>
  <div class="metric-history-container">
    <div class="row show-period">
      <div class="col-md-4"></div>
      <div class="col-md-7">
        <div class="row">
          <div
            v-for="(history, index) in metricHistory"
            :key="index"
            class="col-md-2 metric-history-header"
          >
            <div
              :class="[
                { 'merger-restated': history.merger_restated },
                'metric-period'
              ]"
            >
              {{ spanPeriod[spanId] }}
              <i
                v-if="history.merger_restated && canShowMergerFlag"
                class="cuis-flag merger-restated-flag"
                title="Merger Restated"
                v-b-modal.merger-history
              ></i>
            </div>
            <div class="metric-period">
              {{ history.name | toMetricPeriod }}
            </div>
          </div>
          <div class="col-md-2 nat-header">
            <div class="nat-header text-center nat-period">Nat</div>
            <div class="nat-header text-center nat-period">Pctle</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-2">
        <div class="text-ellipsis metric-detail-bank-name">
          {{ selectedInstitution.name }}
        </div>
      </div>
      <div class="col-md-7 metric-history">
        <div class="row">
          <div
            v-for="(history, index) in metricHistory"
            :key="index"
            class="col-md-2 metric-history-values"
          >
            <div class="metric-value">
              {{ history.bank.value | formatValue(metricDetail.format) }}
            </div>
          </div>
          <div class="col-md-2 nat-values">
            <div class="nat-value">
              {{ nationalRank.national_rank | formatValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-2">
        <div>
          <Multiselect
            track-by="id"
            label="name"
            v-model="peerGroup"
            :allowEmpty="false"
            :showLabels="false"
            :value="59"
            :custom-label="PeerGroupNameWithoutPrefix"
            :options="viewablePeerGroups"
            class="simple-select analytics-select"
          ></Multiselect>
        </div>
      </div>
      <div class="col-md-7 metric-history">
        <div class="row">
          <div
            v-for="(history, index) in metricHistory"
            :key="index"
            class="col-md-2 metric-history-values"
          >
            <div class="metric-value peer-metric-value">
              {{ history.peer.value | formatValue(metricDetail.format) }}
            </div>
          </div>
          <div class="col-md-2 nat-values">
            <div class="nat-value peer-metric-value">
              {{ peerGroupNationalRank.national_rank | formatValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="metric-desc">
      {{ metricDetail.description }}
    </div>
    <MergerHistoryModal></MergerHistoryModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import MergerHistoryModal from './MergerHistoryModal'

export default {
  name: 'MetricsView',
  components: {
    Multiselect,
    MergerHistoryModal
  },
  props: {
    metricHistory: {
      type: Array,
      required: true
    },
    viewablePeerGroups: {
      type: Array,
      required: true
    },
    metricDetail: {
      type: Object,
      required: true
    },
    nationalRank: {
      type: Object,
      required: true
    },
    peerGroupNationalRank: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'spanId',
      'setId',
      'selectedInstitution',
      'selectedPeerGroup'
    ]),
    peerGroup: {
      get: function() {
        return this.selectedPeerGroup
      },
      set: function(peerGroup) {
        this.updatePeerGroup(peerGroup)
      }
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }
    },
    canShowMergerFlag() {
      return this.setId === 2
    }
  },
  methods: {
    ...mapMutations('Advisor', ['setPeerGroup']),
    updatePeerGroup(peerGroup) {
      this.setPeerGroup({ id: peerGroup.id, name: peerGroup.name })
      this.$emit('peerGroupUpdated')
    },
    PeerGroupNameWithoutPrefix(peerGroup) {
      if (peerGroup.creator_id == null) {
        let peerGroupName = peerGroup.name
        return peerGroupName.replace(
          /(Strategic Focus: |Asset Group: |Market Peers: )/,
          ''
        )
      } else {
        return peerGroup.name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/_advisor-variables';

.metric-detail-bank-name {
  padding: 0 0.2rem;
}

.select2-container--default .select2-selection--single {
  height: 24px;
  .select2-selection__arrow {
    height: 22px;
  }
  .select2-selection__rendered {
    line-height: 22px;
  }
}

.show-period {
  padding-bottom: 0.5rem;
}

.metric-history {
  .col-md-2 {
    padding: 0 0.25rem;
  }
}

.metric-history-header,
.nat-history-header,
.nat-header {
  text-align: center;
  .metric-period,
  .nat-period {
    display: inline-block;
    padding: 0.0625rem 0.125rem;
    width: 3.2rem;
    text-align: center;

    &:first-child {
      border-top: 3px solid transparent;
    }

    &:last-child {
      border-bottom: 1px solid $gray;
    }
  }
}

.metric-history-header {
  &:nth-child(5) {
    .metric-period {
      color: $brand_blue;
      border-color: $brand_blue;
    }
  }
}

.nat-header,
.nat-values {
  color: #9ba3af;
  text-align: left;
}

.nat-values {
  width: 2rem;
}

.metric-history-values,
.nat-values {
  text-align: center;
  .metric-value,
  .nat-value {
    text-align: right;
    width: 3.2rem;
    display: inline-block;
    padding: 0 0.125rem;
    white-space: nowrap;
  }
  .nat-value {
    width: 2rem;
  }

  .peer-metric-value {
    padding-top: 0.125rem;
  }
}

.metric-desc {
  background-color: $light_gray;
  padding: 0.625rem 0.75rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  height: 8rem;
  overflow: auto;
}

.merger-restated {
  .merger-restated-flag {
    cursor: pointer;
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '../../../../scss/_advisor-variables';
</style>
