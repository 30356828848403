<template>
  <div class="row justify-content-center decision-tree">
    <div class="col-sm-12">
      <b-card
        class="std-border mb-3"
        :body-class="[
          selectingPeerGroup ? 'tag-disabled' : '',
          'px-3 pb-3 pt-0 sub-card'
        ]"
        header-class="px-2 pt-2 pb-0"
      >
        <div slot="header">
          <div class="row ml-0 mr-0">
            <b-col sm="12" md="4" class="pl-2 pr-2">
              <div>
                <h2 class="section--heading text-uppercase">
                  DECISION TREE
                  <i
                    class="icon-info icons small-line-icon"
                    v-b-modal.dt-info
                  ></i>
                </h2>
              </div>
              <h5 class="section--heading-note">Where to focus?</h5>
              <b-modal
                size="lg"
                id="dt-info"
                class="advisor-info-modal"
                title="Decision Tree"
                ok-only
              >
                <div v-if="isSelectedCreditUnion">
                  <p>
                    Performance navigation is provided to the user through an
                    interactive color-coded decision tree decomposing
                    performance drivers for either profit, growth or risk.
                    Performance is classified as Excellent, Strong, Solid,
                    Moderate or Trailing. Some metrics are unclassifed due to an
                    inability assign a better/worse conclusion (ex. loan
                    composition) or the metric’s area of coverage is not
                    applicable to the subject institution or its peers.
                  </p>
                </div>
                <div v-else>
                  <p>
                    Performance navigation is provided to the user through an
                    interactive color-coded decision tree decomposing
                    performance drivers for either profit, growth or risk.
                    Performance is classified as Excellent, Strong, Solid,
                    Moderate or Trailing. Some metrics are unclassifed due to an
                    inability assign a better/worse conclusion (ex. loan
                    composition) or the metric’s area of coverage is not
                    applicable to the subject institution or its peers.
                  </p>
                </div>
              </b-modal>
            </b-col>
            <b-col sm="10" md="7" class="pl-2 pr-3">
              <div>
                <h2 class="section--heading">{{ reportType }}</h2>
              </div>
              <h5 class="section--heading-note">
                Navigation for {{ reportType }} Enhancement
              </h5>
            </b-col>
            <b-col
              sm="2"
              md="1"
              class="pt-2 d-flex justify-content-end align-items-baseline"
            >
              <label
                :class="[
                  getIsFavorite ? 'favorite__star__selected' : 'favorite__star'
                ]"
              >
                <input
                  class="favorite__checkbox"
                  type="checkbox"
                  name="isFavorite"
                  value="getIsFavorite"
                  @click="toggleFavorite()"
                />
                <b> &#x2605;</b>
              </label>
              <span
                class="icon-printer pl-1 pt-1"
                @click="$emit('print-page')"
              ></span>
            </b-col>
          </div>
        </div>
        <div class="row">
          <b-col sm="12" md="4" class="pl-3 pr-2">
            <div class="sub-card-left rounded">
              <v-wait for="updatingTreeData">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="11"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div id="regionDiagram" ref="regionDiagram" class="mt-3">
                  <v-wait for="loadingTreeData">
                    <template slot="waiting">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="7"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                  </v-wait>
                </div>
                <div id="smallDiagram" ref="smallDiagram">
                  <v-wait for="loadingTreeData">
                    <template slot="waiting">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="4"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                  </v-wait>
                </div>
              </v-wait>
            </div>
          </b-col>
          <b-col sm="12" md="8" class="pl-2 pr-3">
            <div class="sub-card-right rounded">
              <v-wait for="updatingTreeData">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="11"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div id="localDiagram" ref="localDiagram" class="mt-3">
                  <v-wait for="loadingTreeData">
                    <template slot="waiting">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="9"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                  </v-wait>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <v-wait for="loadingTreeData">
                      <template slot="waiting">
                        <div class="col col-sm-12">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="2"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </div>
                      </template>
                      <div class="legend d-flex justify-content-center">
                        <div class="trailing pr-dot-25">
                          <span>Trailing</span>
                        </div>
                        <div class="moderate pr-dot-25">
                          <span>Moderate</span>
                        </div>
                        <div class="solid pr-dot-25"><span>Solid</span></div>
                        <div class="strong pr-dot-25"><span>Strong</span></div>
                        <div class="excellent pr-dot-25">
                          <span>Excellent</span>
                        </div>
                        <div class="unclassified">
                          <span>Unclassified</span>
                        </div>
                      </div>
                    </v-wait>
                  </div>
                </div>
              </v-wait>
            </div>
          </b-col>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { decisionTree, treeResize } from '../mixins/decision_tree.gojs'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DecisionTree',
  props: [
    'reportType',
    'reportSubType',
    'reportId',
    'productId',
    'treeId',
    'metricId',
    'bankServiceId'
  ],
  mounted() {
    this.$wait.start('loadingTreeData')
    this.fetchTreeData().then(() => {
      this.$wait.end('loadingTreeData')
      decisionTree(this)
    })
  },
  data() {
    return {
      treeData: null,
      defaultMetricId: null,
      isFavorite: false,
      metricName: null
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded,
      sidebarApps: state => state.Apps.sidebarApps
    }),
    ...mapState('Advisor', {
      treeSpanData: state => state.treeData,
      selectingPeerGroup: state => state.selectingPeerGroup,
      selectedInstitution: state => state.selectedInstitution
    }),
    ...mapGetters('Advisor', [
      'spanId',
      'setId',
      'menuData',
      'isSelectedCreditUnion',
      'keyMetrics',
      'profitFlag'
    ]),
    getIsFavorite() {
      return this.keyMetricIds.includes(parseInt(this.metricId))
    },
    keyMetricIds() {
      return this.keyMetrics.map(met => met.id)
    }
  },
  methods: {
    ...mapMutations('Advisor', [
      'addCuKeyMetric',
      'addBankKeyMetric',
      'addBhcKeyMetric',
      'removeCuKeyMetric',
      'removeBankKeyMetric',
      'removeBhcKeyMetric'
    ]),
    fetchTreeData() {
      return this.$http
        .get('/api/metrics/' + this.metricId + '/decision_tree', {
          params: {
            product_id: this.productId,
            tree_id: this.treeId,
            bank_service_id: this.bankServiceId,
            report_id: this.reportId,
            set_id: this.setId,
            partner: this.partnerSite,
            efficiency_ratio: this.profitFlag ? null : true
          },
          handleErrors: true
        })
        .then(res => {
          this.treeData = res.data
        })
    },
    updateTreeData() {
      this.$wait.start('updatingTreeData')
      this.fetchTreeData().then(() => {
        this.$wait.end('updatingTreeData')
        this.$nextTick(function() {
          decisionTree(this)
        })
      })
    },
    updateMetricValues(metricId, metricName) {
      this.metricName = metricName
      let selectedMetric = this.menuData.find(
        menu =>
          menu.product_id === this.productId && menu.metric_id === metricId
      )
      if (selectedMetric) {
        let metricPath = this.metricUrl(selectedMetric)
        if (metricPath === this.$route.path) {
          this.$emit('updateMetricId', metricId)
        } else {
          this.$router.push(metricPath)
        }
      } else {
        this.$emit('updateMetricId', metricId)
      }
    },
    metricUrl(metric) {
      let advisorApp = this.sidebarApps.find(app => app.name === 'FIN Advisor')
      let reportType = advisorApp.children.find(
        child => child.name === this.reportType
      )
      return reportType.children.find(type => type.name === metric.menu_display)
        .url
    },
    toggleFavorite() {
      if (!this.getIsFavorite) {
        this.addMetric()
      } else {
        this.removeMetric()
      }
    },
    removeMetric() {
      this.$http
        .post('/api/key_metrics/remove_metric', {
          institution_id: this.selectedInstitution.id,
          metric_id: this.metricId,

          handleErrors: true
        })
        .then(res => {
          if (res.data.updated) {
            let metric = {
              name: this.metricName,
              id: this.metricId
            }
            if (this.isSelectedCreditUnion) {
              this.removeCuKeyMetric(metric)
            } else if (this.isSelectedBhc) {
              this.removeBhcKeyMetric(metric)
            } else {
              this.removeBankKeyMetric(metric)
            }
            let path = this.$route.path.replace('/key_metrics', '')
            this.$router.push(path)
          }
        })
    },
    addMetric() {
      this.$http
        .post('/api/key_metrics/add_metric', {
          institution_id: this.selectedInstitution.id,
          metric_id: this.metricId,

          handleErrors: true
        })
        .then(res => {
          if (res.data.updated && res.data.updated != 'Already present') {
            let url = this.$route.path + '?metricId=' + this.metricId
            if (!url.includes('key_metrics')) {
              url = url.replace('advisor', 'advisor/key_metrics')
            }
            let metric = {
              name: this.metricName,
              url: url,
              type: 'advisor-graph',
              class: 'sub-nav-child',
              id: this.metricId
            }
            if (this.isSelectedCreditUnion) {
              this.addCuKeyMetric(metric)
            } else if (this.isSelectedBhc) {
              this.addBhcKeyMetric(metric)
            } else {
              this.addBankKeyMetric(metric)
            }
          }
        })
    }
  },
  watch: {
    spanId() {
      decisionTree(this)
    },
    isSidebarExpanded: function() {
      treeResize()
    },
    treeSpanData: function() {
      this.treeData = this.treeSpanData
      decisionTree(this)
    }
  }
}
</script>

<style lang="scss">
span.icon-printer {
  cursor: pointer;
}
.decision-tree {
  #regionDiagram,
  #smallDiagram,
  #localDiagram {
    canvas:focus {
      outline: none;
    }
  }

  #regionDiagram {
    height: 11.8rem;
  }

  #smallDiagram {
    height: 6rem;
  }

  #localDiagram {
    height: 14.544rem;
  }

  .sub-card {
    background-color: #f0f3f5;

    .sub-card-left,
    .sub-card-right {
      border: solid 1px lightgray;
      background-color: white;
    }
  }

  .legend {
    font-size: 0.8125rem;
    padding: 1rem;

    @media (max-width: 991.98px) {
      padding: 0.4rem 0.25rem;
      text-align: center;
    }

    .trailing,
    .strong,
    .unclassified,
    .moderate,
    .excellent,
    .solid {
      &:before {
        content: ' ';
        border-radius: 100%;
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.25rem;
      }

      span {
        vertical-align: middle;
      }
    }

    .trailing:before {
      background-color: #d64800;
    }

    .moderate:before {
      background-color: #dec000;
    }

    .solid:before {
      background-color: #2f78ba;
    }
    .strong:before {
      background-color: #349c34;
    }
    .excellent:before {
      background-color: #004d00;
    }

    .unclassified:before {
      background-color: #bbbdbf;
    }
  }

  .loader-cnt {
    background-color: white;
  }
  .favorite__checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .favorite__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    -webkit-text-stroke: 1px grayscale($color: #686262fb);
    // text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    //   1px 1px 0 #000;
  }
  .favorite__star__selected {
    color: #a7ca77;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.2rem;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
  }
}
</style>
