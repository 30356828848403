import Vue from 'vue'
import axios from 'axios'

export default {
  updateBDI(fiID, bdis) {
    return axios
      .post(
        `/api/user_edits/${fiID}/update_bdi`,
        {
          bdis: bdis
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        () => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
          return Promise.reject('update bdi failed')
        }
      )
  },
  createVendor(fiID, bsID, params) {
    return axios
      .post(
        `/api/user_edits/${fiID}/${bsID}/new_vendor`,
        {
          vendor: params
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        () => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )

          return Promise.reject('vendor creation failed')
        }
      )
  },
  createProduct(fiID, bsID, params) {
    return axios
      .post(
        `/api/user_edits/${fiID}/${bsID}/new_product`,
        {
          product: params
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        () => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
          return Promise.reject('product creation failed')
        }
      )
  }
}
