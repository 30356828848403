<template>
  <b-modal
    id="peerGroupModal"
    modal-class="peer-group-modal"
    v-model="show"
    size="lg"
    ref="peerGroupModal"
    title="Peer Group View"
    @show="fetchPeerGroupData"
    no-close-on-backdrop
    no-close-on-esc
  >
    <template slot="modal-header">
      <div class="row">
        <b-col sm="12" md="6" class="pl-3 pr-2">
          <h4 class="mb-0">Peer Group View</h4>
          <div>{{ selectedPeerGroup.name }}</div>
        </b-col>
        <b-col sm="10" md="4" offset-md="2" class="pl-2 pr-3">
          <h4 class="mb-0">{{ primaryTitle }}</h4>
          <div>{{ secondaryTitle }}</div>
        </b-col>
      </div>
      <button type="button" class="close" @click="closeModal">x</button>
    </template>

    <v-wait for="fetchingPeerMetrics">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="7"></content-placeholders-text>
        </content-placeholders>
      </template>

      <div mt-5 mb-5 class="text-center peer-metrics">
        <b-container class="bv-example-row">
          <b-row>
            <b-col sm="6" lg="4">
              <b-form-group label="Search" :label-cols="3" :horizontal="true">
                <b-form-input v-model="searchText"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6" lg="3" offset-md="5" @click="downloadXlsx">
              <b-form-group
                :label-cols="7"
                :horizontal="true"
                class="pull-right cursor-pointer pt-download-btn"
                label="Download"
              >
                <i
                  class="cui-file-excel card-icon-font font-4xl"
                  title="Download xlsx"
                ></i>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>

        <b-table
          v-if="peerMetrics"
          :items="peerMetrics"
          :fields="fieldsList"
          :filter="searchText"
          :sort-compare="sortPeerMetrics"
          :show-empty="true"
          empty-text="No records found."
          empty-filtered-text="There are no records matching the filter."
          @filtered="filteredList"
          responsive="md"
          striped
          hover
          small
        >
          <template v-slot:head(bank_name)="data">
            <div class="text-left">
              <div>{{ data.label }}</div>
            </div>
          </template>
          <template v-slot:head(city)="data">
            <div class="text-left">
              <div>{{ data.label }}</div>
            </div>
          </template>
          <template v-slot:head(state)="data">
            <div class="text-left">
              <div>{{ data.label }}</div>
            </div>
          </template>
          <template v-slot:head(value_1)="data" v-bind:fieldsList="fieldsList">
            <div class="text-right">
              <div class="d-inline-block">
                <div class="text-center">{{ fieldsList[3].label[0] }}</div>
                {{ fieldsList[3].label[1] }}
              </div>
            </div>
          </template>
          <template v-slot:head(value_2)="data" :fieldsList="fieldsList">
            <div class="text-right">
              <div class="d-inline-block">
                <div class="text-center">{{ fieldsList[4].label[0] }}</div>
                {{ fieldsList[4].label[1] }}
              </div>
            </div>
          </template>
          <template v-slot:head(value_3)="data" :fieldsList="fieldsList">
            <div class="text-right">
              <div class="d-inline-block">
                <div class="text-center">{{ fieldsList[5].label[0] }}</div>
                {{ fieldsList[5].label[1] }}
              </div>
            </div>
          </template>
          <template v-slot:head(value_4)="data" :fieldsList="fieldsList">
            <div class="text-right">
              <div class="d-inline-block">
                <div class="text-center">{{ fieldsList[6].label[0] }}</div>
                {{ fieldsList[6].label[1] }}
              </div>
            </div>
          </template>
          <template v-slot:head(value_5)="data" :fieldsList="fieldsList">
            <div class="text-right">
              <div class="d-inline-block">
                <div class="text-center">{{ fieldsList[7].label[0] }}</div>
                {{ fieldsList[7].label[1] }}
              </div>
            </div>
          </template>

          <template v-slot:cell(bank_name)="data">
            <div
              class="text-ellipsis text-left"
              v-b-tooltip
              :title="data.item.bank_name"
            >
              {{ data.item.bank_name }}
            </div>
          </template>
          <template v-slot:cell(city)="data">
            <div
              class="text-ellipsis text-left"
              v-b-tooltip
              :title="data.item.city"
            >
              {{ data.item.city }}
            </div>
          </template>
          <template v-slot:cell(state)="data">
            <div
              class="text-ellipsis text-left"
              v-b-tooltip
              :title="data.item.state"
            >
              {{ data.item.state }}
            </div>
          </template>
          <template v-slot:cell(value_1)="data">
            <div class="text-right">{{ data.item.values[0] || 'NA' }}</div>
          </template>
          <template v-slot:cell(value_2)="data">
            <div class="text-right">{{ data.item.values[1] || 'NA' }}</div>
          </template>
          <template v-slot:cell(value_3)="data">
            <div class="text-right">{{ data.item.values[2] || 'NA' }}</div>
          </template>
          <template v-slot:cell(value_4)="data">
            <div class="text-right">{{ data.item.values[3] || 'NA' }}</div>
          </template>
          <template v-slot:cell(value_5)="data">
            <div class="text-right">{{ data.item.values[4] || 'NA' }}</div>
          </template>
        </b-table>
      </div>
      <div v-if="filteredMetrics" class="count-text py-2 mt-2">
        Showing {{ filteredMetrics }} of {{ peerMetrics.length }} entries.
      </div>
    </v-wait>
    <template slot="modal-footer">
      <div class="row">
        <div class="col-sm-12">
          <b-button size variant="primary" @click="closeModal">Close</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PeerGroupModal',
  props: ['show', 'metricId', 'primaryTitle', 'secondaryTitle'],
  data() {
    return {
      peerMetrics: null,
      searchText: null,
      filteredMetrics: null,
      peerHeaderFields: []
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'reportId',
      'spanId',
      'setId',
      'selectedPeerGroup'
    ]),
    fieldsList() {
      return [
        {
          key: 'bank_name',
          label: 'Financial Institution',
          sortable: true,
          thClass: 'bank-name-col pb-3'
        },
        {
          key: 'city',
          label: 'City',
          sortable: true,
          thClass: 'city-name-col pb-3'
        },
        { key: 'state', label: 'State', sortable: true, thClass: 'pb-3' },
        { key: 'value_1', label: this.peerHeaderFields[0], sortable: true },
        { key: 'value_2', label: this.peerHeaderFields[1], sortable: true },
        { key: 'value_3', label: this.peerHeaderFields[2], sortable: true },
        { key: 'value_4', label: this.peerHeaderFields[3], sortable: true },
        { key: 'value_5', label: this.peerHeaderFields[4], sortable: true }
      ]
    }
  },
  methods: {
    fetchPeerGroupData() {
      this.$wait.start('fetchingPeerMetrics')
      this.$http
        .get('/api/metrics/' + this.metricId + '/peer_group_metrics', {
          params: {
            report_id: this.reportId,
            span_id: this.spanId,
            set_id: this.setId
          },
          handleErrors: true
        })
        .then(res => {
          this.peerMetrics = res.data.metrics
          this.filteredMetrics = this.peerMetrics.length
          this.peerHeaderFields = res.data.labels
        })
        .then(() => {
          this.$wait.end('fetchingPeerMetrics')
        })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    sortPeerMetrics(record1, record2, field) {
      let columnCompare
      let fieldNames = {
        bank: 'bank_name',
        city: 'city',
        state: 'state'
      }
      if (['bank', 'city', 'state'].includes(field)) {
        let field1 = record1[fieldNames[field]] || ''
        let field2 = record2[fieldNames[field]] || ''
        columnCompare = field1
          .toString()
          .localeCompare(field2.toString(), undefined, { numeric: true })
      } else {
        let index = parseInt(field.split('_')[1]) - 1
        let field1 = parseFloat(
          (record1.values[index] || '0').replace(/[^0-9.-]+/g, '')
        )
        let field2 = parseFloat(
          (record2.values[index] || '0').replace(/[^0-9.-]+/g, '')
        )
        columnCompare = field1 === field2 ? 0 : field1 < field2 ? -1 : 1
      }
      if (columnCompare === 0) {
        return record1.bank_name < record2.bank_name
          ? -1
          : record1.bank_name > record2.bank_name
          ? 1
          : 0
      } else {
        return columnCompare
      }
    },
    filteredList(list) {
      this.filteredMetrics = list.length
    },
    downloadXlsx() {
      this.$http
        .get('/api/metrics/' + this.metricId + '/peer_group_metrics.xlsx', {
          params: {
            report_id: this.reportId,
            span_id: this.spanId,
            set_id: this.setId
          },
          handleErrors: true,
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'peer_group.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .then(() => {})
    }
  }
}
</script>

<style lang="scss">
.peer-group-modal {
  .pt-download-btn {
    div {
      padding-left: 0px !important;
    }
  }
  .modal-header {
    .row {
      width: 100%;
    }
  }
  .peer-metrics {
    max-height: 25rem;
    overflow-y: scroll;

    table {
      th:focus {
        outline: none;
      }

      .bank-name-col {
        width: 30%;
      }

      .city-name-col {
        width: 15%;
      }
    }
  }
  .count-text {
    color: #4c4d4c;
  }
}

@media (min-width: 992px) {
  .peer-group-modal {
    .modal-dialog {
      max-width: 900px;
    }
  }
}

@media (max-width: 991.98px) {
  .peer-group-modal {
    .modal-dialog {
      max-width: 600px;
    }
  }
}
</style>
