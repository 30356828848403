import { render, staticRenderFns } from "./VendorsProducts.vue?vue&type=template&id=5bf13e9c&scoped=true&"
import script from "./VendorsProducts.vue?vue&type=script&lang=js&"
export * from "./VendorsProducts.vue?vue&type=script&lang=js&"
import style0 from "../../../../../scss/_advisor-custom.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./VendorsProducts.vue?vue&type=style&index=1&id=5bf13e9c&lang=scss&scoped=true&"
import style2 from "./VendorsProducts.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf13e9c",
  null
  
)

export default component.exports