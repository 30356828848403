import * as go from 'gojs'

go.licenseKey =
  '73f943e3b46028a800ca0d2b113f69ef1bb37b619e841ef95e5342f0ef5f68462b9dec7e58d68e95d0ad4efa1d7ac2d8cfd56f21c419043eb731848c45eb87aabb3776e51d0d42dda00126c09efb2ca0af7d75fa96b074a6dd7c84a6b9a8c69d0feaf3d749c90be879711421502bff4cb7a9897aeb12cf4e686e87a7f9f6af4e'

export const decisionTree = function(vm) {
  var myTree = null
  var TreeNode = null
  var RegionNode = null
  var LocalNode = null
  var myRegionDiagram = null
  var myLocalDiagram = null
  var mySmallDiagram = null
  var lastStroked = null
  var lastLocalStroked = null

  var hoverTimerId = 0
  var hoverNode = null

  var localHoverTimerId = 0
  var inTreeChange = false
  var selectedKey = 0
  var spanChange = false
  var initialMetric = vm.defaultMetricId
  var initialKey = null

  vm.treeData.spans[vm.spanId - 1].treeItems.forEach(function(val) {
    if (val.metric.id === initialMetric) {
      initialKey = val.key
    }
  })

  let regionDiagram = go.Diagram.fromDiv('regionDiagram')
  let localDiagram = go.Diagram.fromDiv('localDiagram')
  let smallDiagram = go.Diagram.fromDiv('smallDiagram')
  if (regionDiagram && regionDiagram.div) {
    regionDiagram.div = null
  }
  if (localDiagram && localDiagram.div) {
    localDiagram.div = null
  }
  if (smallDiagram && smallDiagram.div) {
    smallDiagram.div = null
  }

  function initTree() {
    var $ = go.GraphObject.make

    myTree = $(go.Diagram, {
      isReadOnly: true,
      maxSelectionCount: 1
    })

    myRegionDiagram = $(go.Diagram, 'regionDiagram', {
      autoScale: go.Diagram.Uniform,
      maxScale: 1,
      contentAlignment: go.Spot.TopCenter,
      isReadOnly: true,
      layout: $(go.TreeLayout, {
        angle: 90,
        nodeSpacing: 5,
        layerSpacing: 15,
        sorting: go.TreeLayout.SortingAscending,
        comparer: function(a, b) {
          var nodeAData = a.node.data
          var nodeBData = b.node.data
          if (nodeAData.tree_sequence < nodeBData.tree_sequence) return -1
          if (nodeAData.tree_sequence > nodeBData.tree_sequence) return 1
          return 0
        }
      }),
      maxSelectionCount: 1
    })
    myRegionDiagram.animationManager.isEnabled = false

    myLocalDiagram = $(go.Diagram, 'localDiagram', {
      autoScale: go.Diagram.Uniform,
      maxScale: 1,
      contentAlignment: go.Spot.TopCenter,
      isReadOnly: true,
      layout: $(go.TreeLayout, {
        angle: 90,
        layerSpacing: 50,
        sorting: go.TreeLayout.SortingAscending,
        comparer: function(a, b) {
          var nodeAData = a.node.data
          var nodeBData = b.node.data
          if (nodeAData.tree_sequence < nodeBData.tree_sequence) return -1
          if (nodeAData.tree_sequence > nodeBData.tree_sequence) return 1
          return 0
        },
        alignment: go.TreeLayout.AlignmentCenterChildren
      }),
      maxSelectionCount: 1
    })
    myLocalDiagram.animationManager.isEnabled = false

    mySmallDiagram = $(go.Diagram, 'smallDiagram', {
      autoScale: go.Diagram.Uniform,
      maxScale: 1,
      contentAlignment: go.Spot.TopLeft,
      isReadOnly: true,
      layout: $(go.TreeLayout, {
        alignment: go.TreeLayout.AlignmentStart,
        angle: 0,
        compaction: go.TreeLayout.CompactionNone,
        layerSpacing: 16,
        layerSpacingParentOverlap: 1,
        nodeIndent: 3,
        nodeIndentPastParent: 0.88,
        nodeSpacing: 0,
        setsPortSpot: false,
        setsChildPortSpot: false
      }),
      maxSelectionCount: 1
    })
    mySmallDiagram.animationManager.isEnabled = false

    var myRegionNodeTemplate = $(
      go.Node,
      'Vertical',
      {
        locationSpot: go.Spot.Center,
        selectionAdorned: false,
        mouseEnter: function(e, node) {
          enterRegionNode(e, node)
        },
        mouseLeave: function(e, node) {
          leaveRegionNode(e, node)
        }
      },
      $(
        go.TextBlock,
        {
          name: 'rootName',
          margin: 1,
          stroke: '#6c6d70',
          width: 200,
          wrap: go.TextBlock.WrapFit,
          textAlign: 'center'
        },
        new go.Binding('text', 'name', function(v) {
          return v
        })
      ),
      $(
        go.Panel,
        'Vertical',
        $(
          go.Shape,
          'Circle',
          new go.Binding('fill', 'color'),
          new go.Binding('stroke', 'isHighlighted', function(h, node) {
            if (node.part.isSelected) return '#454545'
            return h ? '#454545' : 'transparent'
          }).ofObject(),
          {
            name: 'myRegionNode',
            width: 12,
            height: 12,
            strokeWidth: 2,
            toolTip: $(
              go.Adornment,
              'Auto',
              $(go.Shape, { fill: '#FCF7FC' }),
              $(
                go.TextBlock,
                { margin: 4 },
                new go.Binding('text', 'name', function(v) {
                  return v
                })
              )
            )
          }
        )
      ),
      $(go.Shape, 'RoundedPointer', {
        name: 'Arrow',
        width: 6,
        height: 12,
        stroke: null,
        fill: '#bbbdbf',
        angle: 90
      })
    )

    var myLocalParentTemplate = $(
      go.Node,
      'Vertical',
      {
        locationSpot: go.Spot.Center,
        selectionAdorned: false,
        name: 'localNode'
      },
      $(
        go.TextBlock,
        {
          margin: new go.Margin(0, 0, 5, 0),
          stroke: '#6c6d70',
          width: 200,
          wrap: go.TextBlock.WrapFit,
          textAlign: 'center'
        },
        new go.Binding('text', 'name', function(v) {
          return v
        })
      ),
      $(go.Shape, 'Circle', new go.Binding('fill', 'color'), {
        name: 'LocalParentNode',
        width: 50,
        height: 50,
        strokeWidth: 2
      })
    )

    var myLocalNodeTemplate = $(
      go.Node,
      'Vertical',
      {
        locationSpot: go.Spot.Center,
        selectionAdorned: false,
        name: 'localNode',
        mouseEnter: function(e, node) {
          if (node && node.diagram) {
            node.diagram.clearHighlighteds()
            node.isHighlighted = true
          }
          enterLocalNode(e, node)
        },
        mouseLeave: function(e, node) {
          if (node && node.diagram) {
            node.diagram.clearHighlighteds()
          }
          leaveLocalNode(e, node)
        }
      },
      $(
        go.Panel,
        'Vertical',
        { width: 100 },
        $(
          go.Shape,
          'Circle',
          new go.Binding('fill', 'color'),
          new go.Binding('stroke', 'isHighlighted', function(h, node) {
            if (node.part.isSelected) return '#454545'
            return h ? '#454545' : 'transparent'
          }).ofObject(),
          {
            name: 'myLocalNode',
            width: 50,
            height: 50,
            strokeWidth: 2
          }
        ),
        $(
          go.TextBlock,
          {
            margin: new go.Margin(5, 0, 0, 0),
            stroke: '#6c6d70',
            width: 100,
            wrap: go.TextBlock.WrapFit,
            textAlign: 'center'
          },
          new go.Binding('text', 'name', function(v) {
            return v
          })
        ),
        $(go.Shape, 'RoundedPointer', {
          name: 'Arrow',
          width: 20,
          height: 40,
          stroke: null,
          fill: '#bbbdbf',
          angle: 90
        })
      )
    )

    var mySmallNodeTemplate = $(
      go.Node,
      'Horizontal',
      {
        locationSpot: go.Spot.Left,
        selectionAdorned: false,
        mouseEnter: function(e, node) {
          enterSmallNode(e, node)
        },
        mouseLeave: function(e, node) {
          leaveSmallNode(e, node)
        }
      },
      $(go.Shape, 'Circle', new go.Binding('fill', 'color'), {
        portId: '',
        name: 'mySmallNode',
        width: 7,
        height: 7,
        stroke: null,
        strokeWidth: 2
      }),
      $(
        go.Panel,
        'Horizontal',
        $(
          go.TextBlock,
          {
            margin: new go.Margin(0, 0, 0, 5),
            stroke: '#6c6d70',
            width: 1000,
            wrap: go.TextBlock.WrapFit,
            textAlign: 'left',
            font: '9pt sans-serif'
          },
          new go.Binding('text', 'name', function(v) {
            return v
          })
        )
      )
    )

    var templmap = new go.Map('string', go.Node)
    templmap.add('localparent', myLocalParentTemplate)
    templmap.add('localchild', myLocalNodeTemplate)
    templmap.add('', myLocalNodeTemplate)

    myLocalDiagram.nodeTemplateMap = templmap

    myRegionDiagram.nodeTemplate = myRegionNodeTemplate
    myRegionDiagram.toolManager.hoverDelay = 100

    mySmallDiagram.nodeTemplate = mySmallNodeTemplate

    var myRegionLinkTemplate = $(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        selectable: false,
        fromEndSegmentLength: 5,
        toEndSegmentLength: 5,
        layerName: 'Background'
      },
      $(go.Shape, {
        name: 'Branch'
      })
    )

    var myLocalLinkTemplate = $(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        selectable: false
      },
      $(go.Shape, {
        isPanelMain: true,
        strokeWidth: 2,
        stroke: '#454545'
      })
    )

    var mySmallLinkTemplate = $(
      go.Link,
      {
        routing: go.Link.Orthogonal,
        selectable: false,
        isAnimated: false,
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Left
      },
      $(go.Shape, {
        strokeWidth: 1,
        stroke: '#454545'
      })
    )

    myRegionDiagram.linkTemplate = myRegionLinkTemplate
    myLocalDiagram.linkTemplate = myLocalLinkTemplate
    mySmallDiagram.linkTemplate = mySmallLinkTemplate

    myTree.model = new go.TreeModel(vm.treeData.spans[vm.spanId - 1].treeItems)
    selectedKey = initialKey || vm.treeData.initialKey
    myTree.select(myTree.findPartForKey(selectedKey))

    TreeNode = myTree.selection.first()
    vm.metricName = TreeNode.data.label
    showTreesClick()
    inTreeChange = false

    myTree.addDiagramListener('initialLayoutCompleted', function() {
      TreeNode = myTree.selection.first()
      showTreesClick()
      inTreeChange = false
    })

    myTree.addDiagramListener('ChangedSelection', function() {
      if (spanChange) return
      TreeNode = myTree.selection.first()
      showTreesClick()
      inTreeChange = false
    })

    myRegionDiagram.addDiagramListener('ChangedSelection', function() {
      showRegionOnRegionClick()
    })
    myLocalDiagram.addDiagramListener('ChangedSelection', function() {
      showLocalOnLocalClick()
    })
    mySmallDiagram.addDiagramListener('ChangedSelection', function() {
      showSmallOnSmallClick()
    })
  }

  function showLocalOnLocalClick() {
    if (inTreeChange) return
    LocalNode = myLocalDiagram.selection.first()
    if (LocalNode !== null) {
      selectedKey = LocalNode.data.key
      var metricId = LocalNode.data.metric.id
      myTree.select(myTree.findPartForKey(LocalNode.data.key))
      changeMetric(metricId, LocalNode.data.label)
    }
  }

  function showRegionOnRegionClick() {
    if (inTreeChange) return
    RegionNode = myRegionDiagram.selection.first()
    if (RegionNode !== null) {
      selectedKey = RegionNode.data.key
      var metricId = RegionNode.data.metric.id
      myTree.select(myTree.findPartForKey(RegionNode.data.key))
      changeMetric(metricId, RegionNode.data.label)
    }
  }

  function showSmallOnSmallClick() {
    if (inTreeChange) return
    var SmallNode = mySmallDiagram.selection.first()
    if (SmallNode !== null) {
      selectedKey = SmallNode.data.key
      var metricId = SmallNode.data.metric.id
      myTree.select(myTree.findPartForKey(SmallNode.data.key))
      changeMetric(metricId, SmallNode.data.label)
    }
  }

  function showTreesClick() {
    showRegion()
    RegionNode = myRegionDiagram.findPartForKey(TreeNode.data.key)
    RegionNode.iselected = true
    cleanRegion()
    regionLayout()
    showLocal()
    LocalNode = myLocalDiagram.findPartForKey(TreeNode.data.key)
    LocalNode.iselected = true
    localLayout()
  }
  function showRegion() {
    var node = myTree.selection.first()
    var model = new go.TreeModel()
    var model2 = new go.TreeModel()
    var root = node.findTreeRoot()
    var parent = node.findTreeParentNode()

    var gparent = null
    var ggparent = null
    var gggparent = null

    if (parent !== null) {
      gparent = parent.findTreeParentNode()
    }
    if (gparent !== null) {
      ggparent = gparent.findTreeParentNode()
    }
    if (ggparent !== null) {
      gggparent = ggparent.findTreeParentNode()
    }

    var children = node.findTreeChildrenNodes()

    var nearby

    if (parent === null) {
      nearby = node.findTreeParts(5)
    } else {
      if (gparent === null) {
        nearby = parent.findTreeParts(5)
      } else {
        if (ggparent === null) {
          nearby = gparent.findTreeParts(5)
        } else {
          if (gggparent === null) {
            nearby = ggparent.findTreeParts(5)
          } else {
            if (children.count === 0) {
              nearby = gggparent.findTreeParts(5)
            } else {
              nearby = ggparent.findTreeParts(5)
            }
          }
        }
      }
    }

    for (var it = nearby.iterator; it.next(); ) {
      var n = it.value
      if (n instanceof go.Node) {
        model.addNodeData(n.data)
      }
      if (n instanceof go.Node && n !== root && n === node) {
        model2.addNodeData(n.data)
      }
      if (n instanceof go.Node && n === parent) model2.addNodeData(n.data)
      if (n instanceof go.Node && n === gparent) model2.addNodeData(n.data)
      if (n instanceof go.Node && n === ggparent) model2.addNodeData(n.data)
      if (n instanceof go.Node && n === gggparent) {
        model2.addNodeData(n.data)
      }
    }

    myRegionDiagram.model = model
    mySmallDiagram.model = model2
  }

  var cleanRegion = function() {
    var root = RegionNode.findTreeRoot()
    if (root === myRegionDiagram.findPartForKey('0')) {
      var productId = vm.productId
      if (productId === 1) {
        collaspeLastChildren(root, 4)
        collaspeSpecial()
      }
      if (productId === 2 && root === RegionNode) {
        if (vm.reportSubType === 'Member Growth') {
          collaspeLargeFamilies(RegionNode, root, 5)
        } else {
          collaspeLargeFamilies(RegionNode, root, 4)
        }
      }
      if (productId === 2 && root !== RegionNode) {
        collaspeExtendedFamily(root, 6)
        collaspeLargeFamilies(RegionNode, RegionNode, 4)
        collaspeChildren(RegionNode, 4)
      }
      if (productId === 3 && root === RegionNode) {
        collaspeLargeFamilies(RegionNode, root, 4)
      }
      if (productId === 3 && root !== RegionNode) {
        collaspeExtendedFamily(root, 6)
        collaspeLargeFamilies(RegionNode, RegionNode, 5)
        collaspeChildren(RegionNode, 5)
      }
    } else {
      if (vm.productId === 1) {
        collaspeLargeFamilies(RegionNode, root, 4)
        collaspeChildren(RegionNode, 4)
        collapseSpecific(RegionNode)
      }
      if (vm.productId === 2) {
        collaspeLargeFamilies(RegionNode, root, 4)
      }
      collaspeExtendedFamily(root, 6)
      collaspeNode(RegionNode, 6)
    }
  }
  function collapseSpecific(RegionNode) {
    if (RegionNode.key === 3895) {
      var assetUtil = myRegionDiagram.findPartForKey(4222) //Bank
      myRegionDiagram.commandHandler.collapseTree(assetUtil)
      assetUtil = myRegionDiagram.findPartForKey(4303) // if CU
      myRegionDiagram.commandHandler.collapseTree(assetUtil)
    }

    var otherOpExpense = myRegionDiagram.findPartForKey(4375)
    myRegionDiagram.commandHandler.collapseTree(otherOpExpense)
  }
  function collaspeSpecial() {
    var llpnode = myRegionDiagram.findPartForKey(387)
    myRegionDiagram.commandHandler.collapseTree(llpnode)
    var taxnode = myRegionDiagram.findPartForKey(544)
    myRegionDiagram.commandHandler.collapseTree(taxnode)
  }

  function collaspeLastChildren(start, x) {
    var gcit, ggcit
    var children = start.findTreeChildrenNodes()
    if (children.count === 0) return

    for (var cit = children.iterator; cit.next(); ) {
      var cn = cit.value
      var gchildren = cn.findTreeChildrenNodes()
      if (gchildren.count === 0) continue
      for (gcit = gchildren.iterator; gcit.next(); ) {
        var gcn = gcit.value
        var ggchildren = gcn.findTreeChildrenNodes()
        if (ggchildren.count === 0) continue
        for (ggcit = ggchildren.iterator; ggcit.next(); ) {
          var ggcn = ggcit.value
          var gggchildren = ggcn.findTreeChildrenNodes()
          if (gggchildren.count === 0) continue
          if (gggchildren.count >= x) {
            myRegionDiagram.commandHandler.collapseTree(ggcn)
          }
        }
      }
    }
  }

  function collaspeExtendedFamily(node) {
    var tree = node.findTreeParts()
    for (var it = tree.iterator; it.next(); ) {
      var n = it.value
      if (n instanceof go.Node) {
        if (RegionNode.isInTreeOf(n)) {
          continue
        } else {
          myRegionDiagram.commandHandler.collapseTree(n)
        }
      }
    }
    myRegionDiagram.commandHandler.expandTree(RegionNode)
  }

  function collaspeNode(node, x) {
    var children = node.findTreeChildrenNodes()
    if (children.count >= x) {
      myRegionDiagram.commandHandler.collapseTree(node)
    }
  }

  function collaspeChildren(node, x) {
    var children = node.findTreeChildrenNodes()
    if (children.count >= x) {
      for (var it = children.iterator; it.next(); ) {
        var n = it.value
        myRegionDiagram.commandHandler.collapseTree(n)
      }
    }
  }

  function collaspeLargeFamilies(node, root, x) {
    var gcit, ggcit
    var children = root.findTreeChildrenNodes()
    if (children.count === 0) {
      return
    }
    for (var cit = children.iterator; cit.next(); ) {
      var cn = cit.value
      if (node === cn) continue
      var gchildren = cn.findTreeChildrenNodes()
      if (gchildren.count === 0) continue
      for (gcit = gchildren.iterator; gcit.next(); ) {
        var gcn = gcit.value
        if (node === gcn) continue
        var ggchildren = gcn.findTreeChildrenNodes()
        if (ggchildren.count === 0) continue
        for (ggcit = ggchildren.iterator; ggcit.next(); ) {
          var ggcn = ggcit.value
          if (node === ggcn) continue
          var gggchildren = ggcn.findTreeChildrenNodes()
          if (gggchildren.count === 0) continue
          if (gggchildren.count >= x - 2) {
            //console.log('gggchildren.count >= x - 2' + ggcn.data.label)
            myRegionDiagram.commandHandler.collapseTree(ggcn)
          }
        }
        if (ggchildren.count >= x - 1 && !node.isInTreeOf(gcn)) {
          myRegionDiagram.commandHandler.collapseTree(gcn)
        }
      }
      if (gchildren.count >= x && !node.isInTreeOf(cn)) {
        myRegionDiagram.commandHandler.collapseTree(cn)
      }
    }
  }

  function showLocal() {
    var node = myTree.selection.first()
    var nearby

    if (node !== null) {
      var model = new go.TreeModel()

      var child = node.findTreeChildrenNodes()
      if (child.count === 0) {
        // if (parent !== null) {
        var parent = node.findTreeParentNode()
        nearby = parent.findTreeParts(2)
        // }
      } else {
        nearby = node.findTreeParts(2)
      }
    }

    for (var it = nearby.iterator; it.next(); ) {
      var n = it.value
      if (n instanceof go.Node) model.addNodeData(n.data)
    }

    myLocalDiagram.model = model
  }

  function regionLayout() {
    myRegionDiagram.startTransaction('change Layout')
    var root = RegionNode.findTreeRoot()
    var tree = root.findTreeParts()

    for (var it = tree.iterator; it.next(); ) {
      var n = it.value
      if (n instanceof go.Node) {
        var shape = n.findObject('myRegionNode')
        if (shape !== null) {
          shape.stroke = null
        }
        if (n === RegionNode && shape !== null) {
          shape.stroke = '#454545'
        }

        var text = n.findObject('rootName')
        if (text !== null) {
          text.visible = false
        }
        if (n === root && shape !== null) {
          text.visible = true
        }
      }

      if (n instanceof go.Link) {
        let shape = n.findObject('Branch')
        if (shape !== null) {
          shape.stroke = '#bbbdbf'
          shape.strokeWidth = 1.5
        }
        let links = RegionNode.findLinksOutOf()
        for (let lit = links.iterator; lit.next(); ) {
          let nl = lit.value
          if (n === nl && shape !== null) {
            shape.stroke = '#454545'
            shape.strokeWidth = 2
          }
        }
      }

      var tnode = myTree.findPartForKey(n.data.key)
      var leaf = n.isTreeLeaf
      var tleaf = tnode.isTreeLeaf
      var col = n.isTreeExpanded
      var arrow = n.findObject('Arrow')
      if (arrow !== null) {
        arrow.visible = false
      }
      if (leaf && !tleaf && arrow !== null) {
        arrow.visible = true
      }
      if (!col && !tleaf && arrow !== null) {
        arrow.visible = true
      }
    }

    var nd = RegionNode

    do {
      var links = nd.findLinksInto()
      for (let lit = links.iterator; lit.next(); ) {
        let nl = lit.value
        let shape = nl.findObject('Branch')
        if (shape !== null) {
          shape.stroke = '#454545'
          shape.strokeWidth = 3
          nl.layerName = ''
        }
      }
      nd = nd.findTreeParentNode()
    } while (nd !== null)

    myRegionDiagram.commitTransaction('change Layout')
  }

  function localLayout() {
    myLocalDiagram.startTransaction('change Layout')
    var lay = myLocalDiagram.layout
    var child = LocalNode.findTreeChildrenNodes()
    var root = LocalNode.findTreeRoot()
    var tree = root.findTreeParts()
    var nchild

    if (child.count >= 1) {
      myLocalDiagram.model.setCategoryForNodeData(LocalNode.data, 'localparent')

      for (var it = child.iterator; it.next(); ) {
        myLocalDiagram.model.setCategoryForNodeData(it.value.data, 'localchild')
      }
      nchild = child.count
    }
    if (child.count === 0) {
      var parent = LocalNode.findTreeParentNode()
      myLocalDiagram.model.setCategoryForNodeData(parent.data, 'localparent')
      var children = parent.findTreeChildrenNodes()

      for (let it = children.iterator; it.next(); ) {
        myLocalDiagram.model.setCategoryForNodeData(it.value.data, 'localchild')
      }
      nchild = children.count
    }

    if (nchild <= 2) {
      lay.nodeSpacing = 300
    }
    if (nchild === 3) {
      lay.nodeSpacing = 100
    }
    if (nchild === 4) {
      lay.nodeSpacing = 33
    }
    if (nchild === 5) {
      lay.nodeSpacing = 0
    }
    if (nchild === 6) {
      lay.nodeSpacing = 0
    }
    if (nchild === 7) {
      lay.nodeSpacing = 0
    }
    if (nchild === 8) {
      lay.nodeSpacing = 33
    }
    if (nchild === 9) {
      lay.nodeSpacing = 0
    }
    if (nchild === 10) {
      lay.nodeSpacing = 0
    }
    if (nchild === 11) {
      lay.nodeSpacing = 0
    }
    if (nchild >= 12) {
      lay.nodeSpacing = 0
    }
    if (nchild >= 7) {
      lay.fromEndSegmentLength = 5
      lay.toEndSegmentLength = 5
    }

    lay.breadthLimit = 800

    for (let it = tree.iterator; it.next(); ) {
      var n = it.value
      if (n instanceof go.Node) {
        var lshape = n.findObject('myLocalNode')
        if (lshape !== null) {
          lshape.stroke = null
        }
        if (n === LocalNode && lshape !== null) {
          lshape.stroke = '#454545'
        }

        var lpshape = n.findObject('LocalParentNode')
        if (lpshape !== null) {
          lpshape.stroke = null
        }
        if (n === LocalNode && lpshape !== null) {
          lpshape.stroke = '#454545'
        }

        var tnode = myTree.findPartForKey(n.data.key)
        var leaf = n.isTreeLeaf
        var tleaf = tnode.isTreeLeaf
        var arrow = n.findObject('Arrow')
        if (arrow !== null) {
          arrow.visible = false
        }
        if (leaf && !tleaf && arrow !== null) {
          arrow.visible = true
        }
      }
    }
    myLocalDiagram.commitTransaction('change Layout')
  }

  var enterRegionNode = function(e, obj) {
    if (!obj || hoverNode === obj) {
      clearTimeout(hoverTimerId)
      return
    }
    obj.isHighlighted = true
    obj.cursor = 'pointer'

    var shape = obj.part.findObject('myRegionNode')
    shape.stroke = '#454545'
    if (
      lastStroked !== null &&
      lastStroked !== shape &&
      lastStroked !== RegionNode.findObject('myRegionNode')
    ) {
      lastStroked.stroke = null
    }
    lastStroked = shape

    clearTimeout(hoverTimerId)
    hoverTimerId = setTimeout(function() {
      hoverTimerId = 0
    }, 500)
  }
  var leaveRegionNode = function(e, obj) {
    clearTimeout(hoverTimerId)
    hoverTimerId = setTimeout(function() {
      hoverTimerId = 0
      obj.cursor = 'default'
      if (
        lastStroked !== null &&
        lastStroked !== RegionNode.findObject('myRegionNode')
      ) {
        lastStroked.stroke = null
      }
      lastStroked = null
    }, 250)
  }

  var enterLocalNode = function(e, obj) {
    clearTimeout(localHoverTimerId)

    obj.cursor = 'pointer'
    var shape = obj.part.findObject('myLocalNode')
    if (shape) {
      shape.stroke = '#454545'
    }
    if (
      lastLocalStroked !== null &&
      lastLocalStroked !== shape &&
      lastLocalStroked !== LocalNode.findObject('myLocalNode')
    ) {
      lastLocalStroked.stroke = null
    }
    lastLocalStroked = shape
  }
  var leaveLocalNode = function(e, obj) {
    localHoverTimerId = setTimeout(function() {
      obj.cursor = 'default'
      if (
        lastLocalStroked !== null &&
        lastLocalStroked !== LocalNode.findObject('myLocalNode')
      ) {
        lastLocalStroked.stroke = null
      }
      lastLocalStroked = null
    }, 250)
  }

  var enterSmallNode = function(e, obj) {
    obj.cursor = 'pointer'
  }
  var leaveSmallNode = function(e, obj) {
    obj.cursor = 'default'
  }

  var changeMetric = function(metricId, metricName) {
    vm.defaultMetricId = metricId
    vm.updateMetricValues(metricId, metricName)
  }

  initTree()
}

export const treeResize = () => {
  setTimeout(function() {
    let regionDiagram = go.Diagram.fromDiv('regionDiagram')
    let localDiagram = go.Diagram.fromDiv('localDiagram')
    let smallDiagram = go.Diagram.fromDiv('smallDiagram')

    if (regionDiagram && regionDiagram.div) {
      regionDiagram.requestUpdate()
    }
    if (localDiagram && localDiagram.div) {
      localDiagram.requestUpdate()
    }
    if (smallDiagram && smallDiagram.div) {
      smallDiagram.requestUpdate()
    }
  }, 250)
}

export default decisionTree
