<template>
  <div class="trend-chart-container">
    <div id="column-chart" class="col-chart px-3"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Highcharts from 'highcharts'
import Vue from 'vue'

export default {
  name: 'ChartView',
  props: {
    metricHistory: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    metricDetail: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.drawChart()
  },
  data() {
    return {
      columnChart: null
    }
  },
  computed: {
    ...mapGetters(['fiTypeMapper']),
    ...mapGetters('Advisor', ['selectedInstitution']),
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    bankMetrics() {
      return this.metricHistory.map(
        metric => parseFloat(metric.bank.value) || 0
      )
    },
    peerMetrics() {
      return this.metricHistory.map(metric => metric.peer.value)
    },
    metricPeriods() {
      return this.metricHistory.map(
        (metric => {
          return this.$options.filters.toMetricPeriod(metric.name)
        }).bind(this)
      )
    }
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          this.columnChart.reflow()
        }.bind(this),
        250
      )
    },
    drawChart() {
      let min_of_array = Math.min(...this.bankMetrics, ...this.peerMetrics)
      let max_of_array = Math.max(...this.bankMetrics, ...this.peerMetrics)

      const metricFormat = this.metricDetail.format
      this.columnChart = Highcharts.chart('column-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 25
        },
        exporting: {
          scale: 1,
          filename: this.metricDetail.statement_name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download',
              symbolStroke: this.partnerSite ? '#015B7E' : '#666666'
            }
          },
          chartOptions: {
            title: {
              text: this.metricDetail.statement_name,
              style: {
                color: this.partnerSite ? '#015b7e' : '#333333'
              }
            },
            subtitle: {
              text: this.metricDetail.sec_title
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0,
          itemMarginTop: -11,
          itemMarginBottom: -11
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatValue')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            pointWidth: 40,
            groupPadding: 0.07,
            pointPadding: 0
          }
        },
        xAxis: {
          categories: this.metricPeriods,
          gridLineWidth: 1,
          labels: {
            y: 16,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          min: min_of_array - (max_of_array - min_of_array) * 0.1,
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatValue')(this.value, metricFormat)
            }
          }
        },
        series: [
          {
            name: this.fiTypeMapper[this.selectedInstitution.fi_type],
            data: this.bankMetrics,
            color: this.partnerSite ? '#537CD1' : '#A7CA77'
          },
          {
            name: 'Peers',
            data: this.peerMetrics,
            color: this.partnerSite ? '#FFDC88' : '#414142'
          }
        ]
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.col-chart {
  height: 16.85rem;
}
</style>
