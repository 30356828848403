<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-10 print-area" ref="printArea">
      <PageCardWithPeerGroup
        pageName="Offering Report"
        :logoUrl="s3LogoUrl"
        :institutionName="selectedInstitution.name || ''"
        :cardBodyClasses="['page-content']"
      >
        <template slot="page_content">
          <v-wait for="generatingReport">
            <template slot="waiting">
              <ReportPlaceholder
                :progress="reportProgress || 0"
                :sectionCount="2"
              ></ReportPlaceholder>
            </template>
            <div class="row justify-content-center container--advisor-report">
              <div class="col-sm-12">
                <b-card
                  class="std-border mb-3"
                  body-class="px-3 pb-3 pt-0 sub-card"
                  header-class="px-2 pt-2 pb-0"
                >
                  <div slot="header">
                    <div class="row ml-0 mr-0">
                      <b-col sm="3" md="3" class="pl-2 pr-2">
                        <div>
                          <h2 class="section--heading text-uppercase">
                            Vendors & Products
                          </h2>
                        </div>
                        <h5 class="section--heading-note">
                          Who are our partners?
                        </h5>
                      </b-col>
                      <b-col sm="5" md="5">
                        <b-row>
                          <b-col cols="10" class="pr-1 mt-1">
                            <multiselect
                              v-model="selectedPeerBanks"
                              track-by="id"
                              label="label"
                              :options="filteredPGBanks"
                              :multiple="false"
                              :close-on-select="true"
                              :showLabels="false"
                              :searchable="true"
                              placeholder="Please select"
                              class="simple-select analytics-select page-card-select"
                              @close="updateVendorsAndProducts"
                              v-if="peerGroupBanks.length"
                            >
                              <!-- <template slot="singleLabel" slot-scope="props">
                            <span class="option-text">{{
                              props.option.label
                            }}</span>
                          </template> -->

                              <!-- :options-limit="multiSelectOptionsLimit"
                            <template slot="beforeList">
                            <div
                              class="d-flex justify-content-center alpha-link"
                            >
                              <b-button
                                variant="link"
                                class="px-1"
                                @click="fetchFIsBy(alphabet)"
                                v-for="(alphabet, index) in alphabets"
                                :key="'k_' + index"
                                >{{ alphabet }}</b-button
                              >
                            </div>
                          </template>
                          <template slot="afterList">
                            <div
                              class="d-flex justify-content-center alpha-link py-1"
                              v-if="isMoreOptionsAvailable"
                            >
                              Please use Search or Alphabet link to see more
                              options.
                            </div>
                          </template> -->
                            </multiselect>
                          </b-col></b-row
                        >
                      </b-col>
                      <b-col sm="4" md="4" class="pt-2 float-right">
                        <span
                          class="icon-printer pull-right"
                          @click="printPage"
                        ></span>
                        <span
                          v-b-tooltip.hover
                          title="Submit a Service Update"
                          class="fa fa-pencil-square-o pull-right pr-2 cursor-pointer"
                          v-b-modal.bankServiceVPModal
                        ></span>
                      </b-col>
                    </div>
                  </div>
                  <v-wait for="fetchVendorsAndProducts">
                    <template slot="waiting">
                      <div class="text-center px-3 pb- pt-1 sub-card-content">
                        <content-placeholders :rounded="true" class="loader">
                          <content-placeholders-heading />
                          <content-placeholders-text
                            :lines="15"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </div>
                    </template>
                    <div
                      class="px-3 pb-3 pt-3 sub-card-content text-center"
                      v-if="bankServicesNotPresent"
                    >
                      No service with vendor or product found
                    </div>
                    <div class="px-3 pb-3 pt-1 sub-card-content" v-else>
                      <div class="offerings-header row">
                        <div class="col-md-6">Service</div>
                        <div class="col-md-3">Vendor</div>
                        <div class="col-md-3">Product</div>
                      </div>
                      <div
                        class="offerings"
                        v-for="service in bankServices"
                        :key="service.parent"
                      >
                        <div
                          class="offerings-col"
                          v-if="service.children.length"
                        >
                          <div class="header row">
                            <div class="col-md-7">{{ service.parent }}</div>
                          </div>
                          <ul class="offerings-ul">
                            <li
                              class="offerings-li text-ellipsis"
                              v-for="(child, index) in service.children"
                              :key="index"
                            >
                              <div class="row service-container">
                                <div class="service col-md-6">
                                  {{ child.service }}
                                </div>
                                <div class="col-md-3">{{ child.vendor }}</div>
                                <div class="col-md-3">{{ child.product }}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </v-wait>
                </b-card>
              </div>
            </div>
            <bank-service-v-p-modal
              :financialInstituion="selectedInstitution"
            ></bank-service-v-p-modal>
          </v-wait>
        </template>
      </PageCardWithPeerGroup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router'
import { mapGetters, mapActions } from 'vuex'
import PageCardWithPeerGroup from '../../components/layouts/PageCardWithPeerGroup'
import ReportPlaceholder from '../../components/ReportGeneratorPlaceholder'
// ui components
import BankServiceVPModal from '@/modules/online_edits/components/BankServiceVPModal.vue'
import Multiselect from 'vue-multiselect'

export default {
  name: 'vendor-products',
  components: {
    PageCardWithPeerGroup,
    ReportPlaceholder,
    BankServiceVPModal,
    Multiselect
  },
  beforeCreate() {
    let institution = store.getters['Advisor/selectedInstitution']
    let peerGroup = store.getters['Advisor/selectedPeerGroup']

    if (!institution.id) {
      Vue.toasted.show('Please select institution.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/advisor/select_institution')
    } else if (!peerGroup.id) {
      Vue.toasted.show('Please select peer group.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/advisor/select_peer_group')
    }
  },
  created() {
    this.fetchPeerGroupBanks()
    if (this.reportId) {
      this.getVendorsAndProducts()
    } else {
      this.initLoad = true
      this.$wait.start('generatingReport')
      this.getMenuData().then(() => {
        this.getVendorsAndProducts()
        this.initLoad = false
      })
    }
  },
  data() {
    return {
      initLoad: false,
      bankServices: [],
      peerGroupBanks: [],
      filteredPGBanks: [],
      selectedPeerBanks: null
      // multiSelectOptionsLimit: 9,
      // alphabets: [
      //   'A',
      //   'B',
      //   'C',
      //   'D',
      //   'E',
      //   'F',
      //   'G',
      //   'H',
      //   'I',
      //   'J',
      //   'K',
      //   'L',
      //   'M',
      //   'N',
      //   'O',
      //   'P',
      //   'Q',
      //   'R',
      //   'S',
      //   'T',
      //   'U',
      //   'V',
      //   'W',
      //   'X',
      //   'Y',
      //   'Z',
      //   '- All'
      // ]
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'reportId',
      'selectedInstitution',
      'selectedPeerGroup',
      's3LogoUrl',
      'reportProgress'
    ]),
    bankServicesNotPresent() {
      return this.bankServices.every(bs => bs.children.length === 0)
    }
    // emptyValue() {
    //   let value = this.selectedPeerBanks
    //   return Array.isArray(value)
    //     ? this.selectedPeerBanks.length === 0
    //     : [undefined, ''].includes(this.selectedPeerBanks)
    // }
    // isMoreOptionsAvailable() {
    //   return this.filteredPGBanks.length > this.multiSelectOptionsLimit
    // }
  },
  methods: {
    ...mapActions('Advisor', ['getMenuData']),
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    },
    getVendorsAndProducts() {
      this.$wait.start('fetchVendorsAndProducts')
      this.$http
        .get('api/offerings/vendors_and_products', {
          handleErrors: true,
          params: {
            report_id: this.reportId
          }
        })
        .then(res => {
          this.bankServices = res.data.bank_services
        })
        .then(() => this.$wait.end('fetchVendorsAndProducts'))
    },
    fetchPeerGroupBanks() {
      if (this.selectedPeerGroup.id) {
        this.$wait.start('membersLoading')
        this.$http
          .get(`/api/peer_groups/${this.selectedPeerGroup.id}/banks`, {
            handleErrors: true
          })
          .then(res => {
            this.peerGroupBanks = [this.selectedInstitution, ...res.data.banks]
            this.peerGroupBanks.forEach(bank => {
              bank.label = bank.name + ' - ' + bank.city + ', ' + bank.state
            })
            this.filteredPGBanks = this.peerGroupBanks
          })
          .then(() => {
            this.$wait.end('membersLoading')
          })
      }
    },
    // fetchFIsBy(alphabet) {
    //   if (alphabet === '- All') this.filteredPGBanks = this.peerGroupBanks
    //   else
    //     this.filteredPGBanks = this.peerGroupBanks.filter(b =>
    //       b.name.startsWith(alphabet)
    //     )
    // },
    updateVendorsAndProducts() {
      if (this.selectedPeerBanks) {
        this.$wait.start('fetchVendorsAndProducts')
        this.$http
          .get('api/offerings/vendors_and_products', {
            handleErrors: true,
            params: {
              bank_id: this.selectedPeerBanks.id,
              peer_group_id: this.selectedPeerGroup.id
            }
          })
          .then(res => {
            this.bankServices = res.data.bank_services
          })
          .then(() => this.$wait.end('fetchVendorsAndProducts'))
      } else this.getVendorsAndProducts()
    },
    peerGroupUpdated() {
      this.selectedPeerBanks = null
      this.fetchPeerGroupBanks()
      this.getVendorsAndProducts()
    }
  },
  watch: {
    reportProgress: function(newVal) {
      if (newVal != 100) {
        this.$wait.start('generatingReport')
      } else {
        if (!this.initLoad) {
          this.peerGroupUpdated()
        }
        this.$wait.end('generatingReport')
      }
    }
  }
}
</script>

<style lang="scss" src="../../../../../scss/_advisor-custom.scss"></style>

<style lang="scss" scoped>
.card-body {
  padding-top: 0px;
}
.offerings-header {
  margin: 0.5em -0.95rem 0.375rem;
  font-weight: bold;
}
.offerings-col:first-child {
  padding-right: 0.375rem;
}
.icon-printer {
  cursor: pointer;
}
.sub-card {
  background-color: #f0f3f5;
  .sub-card-content {
    border: solid 1px lightgray;
    background-color: white;
  }
}
.offerings-content {
  padding-left: 0.5rem;
}
.service-container {
  padding: 0.125rem 0;
}
.offerings-ul {
  padding-left: 0.95rem;
}
.loader {
  margin: 1rem;
}
.alpha-link {
  text-align: center;
  background-color: #f5f1f1;
}
</style>
<style lang="scss">
.container--advisor-report {
  .multiselect.simple-select {
    .multiselect__tags {
      .multiselect__placeholder {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
}
</style>
