<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-10">
      <BaseGraphReport
        reportType="Growth"
        reportSubType="Share Growth"
        @print-page="printPage"
        :key="componentKey"
      ></BaseGraphReport>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/events.js'
import BaseGraphReport from '../BaseGraphReport'

export default {
  name: 'profit-macro-profitability',
  components: {
    BaseGraphReport
  },
  mounted() {
    EventBus.$on('advisorRouteReActivated', () => {
      if (this.$route.name === this.routeName) {
        this.$route.query.metricId = null
        this.componentKey = this.componentKey + 1
      }
    })
  },
  data() {
    return {
      componentKey: 0,
      routeName: 'GrowthShare'
    }
  },
  methods: {
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    }
  }
}
</script>

<style lang="scss"></style>
