<template>
  <div class="row">
    <!-- LG = 992 - 1200 col-sm-12 col-md-12 col-lg-12 -->
    <div class="col-lg-12 col-xl-12 col-xxl-10 print-area" ref="printArea">
      <BaseGraphReport
        reportType="Profit"
        reportSubType="Macro-Profitability"
        @print-page="printPage"
        :key="componentKey"
      ></BaseGraphReport>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/events.js'
import BaseGraphReport from '../BaseGraphReport'
import { mapMutations } from 'vuex'

export default {
  name: 'profit-macro-profitability',
  components: {
    BaseGraphReport
  },
  mounted() {
    EventBus.$on('advisorRouteReActivated', () => {
      if (this.$route.name === this.routeName) {
        this.$route.query.metricId = null
        this.componentKey = this.componentKey + 1
      }
    })
  },
  created() {
    this.setProfitFlag(true)
  },
  data() {
    return {
      componentKey: 0,
      routeName: 'ProfitMacroProfitability'
    }
  },
  computed: {},
  methods: {
    ...mapMutations('Advisor', ['setProfitFlag']),
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    }
  }
}
</script>

<style lang="scss"></style>
