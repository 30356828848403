import { render, staticRenderFns } from "./BankServiceVPModal.vue?vue&type=template&id=4852ae86&"
import script from "./BankServiceVPModal.vue?vue&type=script&charset=utf-8&lang=js&"
export * from "./BankServiceVPModal.vue?vue&type=script&charset=utf-8&lang=js&"
import style0 from "../scss/online.edits.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./BankServiceVPModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports