<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-10 print-area" ref="printArea">
      <PageCardWithPeerGroup
        pageName="Offering Report"
        :logoUrl="s3LogoUrl"
        :institutionName="selectedInstitution.name"
        :cardBodyClasses="['page-content']"
      >
        <template slot="page_content">
          <v-wait for="generatingReport">
            <template slot="waiting">
              <ReportPlaceholder
                :progress="reportProgress || 0"
                :sectionCount="3"
              ></ReportPlaceholder>
            </template>
            <div class="container--advisor-report">
              <SolutionHierarchy
                :reportId="reportId"
                :bankServiceId="reportBankServiceId"
                @print-page="printPage"
              ></SolutionHierarchy>
              <ActionPlan
                :productId="reportProductId"
                :bankServiceId="reportBankServiceId"
                :metricActionPlan="false"
                class="offering-action-plan"
              ></ActionPlan>
            </div>
          </v-wait>
        </template>
      </PageCardWithPeerGroup>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ReportPlaceholder from '../../components/ReportGeneratorPlaceholder'
import PageCardWithPeerGroup from '../../components/layouts/PageCardWithPeerGroup'
import SolutionHierarchy from '../../components/SolutionHierarchy'
import ActionPlan from '../../components/ActionPlan'

export default {
  name: 'RetailServiceComponenet',
  components: {
    ReportPlaceholder,
    PageCardWithPeerGroup,
    ActionPlan,
    SolutionHierarchy
  },
  data() {
    return {
      reportType: 'Offering',
      reportSubType: 'Retail Services'
    }
  },
  beforeMount() {
    let chosenMetricId = this.metricId(this.reportType, this.reportSubType)
    this.setMetricId(chosenMetricId)
  },
  created() {
    this.$wait.start('generatingReport')
    this.getMenuData().then(() => {
      if (this.reportId && this.reportProgress === 100) {
        this.$wait.end('generatingReport')
      }
    })
  },
  computed: {
    ...mapGetters('Advisor', [
      'selectedInstitution',
      's3LogoUrl',
      'reportId',
      'productId',
      'metricId',
      'selectedMetricId',
      'reportProgress',
      'bankServiceId'
    ]),
    reportProductId() {
      return this.productId(this.reportType)
    },
    reportBankServiceId() {
      return this.bankServiceId(this.reportType, this.reportSubType)
    }
  },
  methods: {
    ...mapActions('Advisor', ['getMenuData']),
    ...mapMutations('Advisor', ['setMetricId']),
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    }
  },
  watch: {
    reportProgress: function(progress) {
      if (progress === 100) {
        this.$wait.end('generatingReport')
      }
    }
  }
}
</script>

<style lang="scss" src="../../../../../scss/_advisor-custom.scss"></style>

<style lang="scss">
.loader {
  margin: 1rem;
}

@media print {
  .container--advisor-report {
    a:link {
      text-decoration: none;
    }
  }
}
</style>
