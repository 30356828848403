<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-10 print-area" ref="printArea">
      <PageCardWithPeerGroup
        pageName="Offering Report"
        renderedIn="Top Technologies"
        :logoUrl="s3LogoUrl"
        :institutionName="selectedInstitution.name || ''"
        :cardBodyClasses="['page-content']"
        @allBanks="peerGroupUpdated(true)"
        @peerGroupUpdated="peerGroupUpdated(false)"
      >
        <template slot="page_content">
          <div v-if="reportLoading">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="15"
              ></content-placeholders-text>
            </content-placeholders>
          </div>

          <div
            class="row justify-content-center container--advisor-report"
            v-else
          >
            <div class="col-sm-12">
              <b-card
                class="std-border mb-3"
                body-class="p-3 pt-1 sub-card"
                header-class="px-2 pt-2 pb-0"
              >
                <div slot="header">
                  <div class="row ml-0 mr-0">
                    <b-col sm="11" md="11" class="pl-2 pr-2">
                      <div>
                        <h2 class="section--heading text-uppercase">
                          Top Technologies
                        </h2>
                      </div>
                      <h5 class="section--heading-note">
                        Which technologies are deployed by peers?
                      </h5>
                    </b-col>

                    <b-col sm="1" md="1" class="pt-2">
                      <span
                        class="icon-printer pull-right"
                        @click="printPage"
                      ></span>
                    </b-col>
                  </div>
                </div>
                <div class="sub-card-content p-3" v-if="isSolutionSelected">
                  <div class="market-report-header">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="">
                          <div class="d-flex align-items-baseline report-title">
                            <div class="pr-2">Technographic -</div>
                            <div>
                              <multiselect
                                track-by="id"
                                label="displayText"
                                :allowEmpty="false"
                                :showLabels="false"
                                :options="getTechnographicsOptions"
                                :searchable="false"
                                class="min-width-20 cursor-pointer dc-report-select market_report"
                                id="technographic"
                                :value="selectedSolution"
                                @select="updateTechnographic"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                >
                                  <div class="d-flex">
                                    <div class="option-text">
                                      {{ option.name }}
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 d-flex">
                        <span class="core-vendor"><b>Core Vendors:</b></span>
                        <multiselect
                          v-model="fiCoreVendor"
                          :options="coreVendorProducts"
                          :multiple="true"
                          :close-on-select="false"
                          placeholder=""
                          label="name"
                          track-by="name"
                          group-values="products"
                          group-label="name"
                          class="simple-select group-select"
                          :group-select="true"
                          :showLabels="false"
                          @close="setCoreVendor"
                        >
                          <template slot="beforeList">
                            <li class="multiselect__elemet p-2 border-bottom">
                              <span
                                @click="clearAllCoreVendor()"
                                class="select-all-option"
                              >
                                <b> Clear All </b>
                              </span>
                            </li>
                          </template>

                          <template slot="selection" slot-scope="{ values }">
                            {{ values.length }}
                            {{ values.length | pluralize('product') }} selected
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-9 report-subtitle py-2">
                        <div class="">
                          {{ reportSubTitleAdvisor }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="market-report-container ">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead v-if="regulatedTableData.length">
                          <tr>
                            <th>Rank</th>
                            <th @click="sortByColumn('vendor')">
                              <span>Vendor Name</span>
                              <span>
                                <i
                                  :class="[
                                    'fa fa-regular sort-icon',
                                    getSortIconClass('vendor')
                                  ]"
                                >
                                </i>
                              </span>
                            </th>
                            <th
                              @click="sortByColumn('product')"
                              v-if="showProduct"
                            >
                              <span>Product Name</span>
                              <span>
                                <i
                                  :class="[
                                    'fa fa-regular sort-icon',
                                    getSortIconClass('product')
                                  ]"
                                >
                                </i>
                              </span>
                            </th>
                            <th
                              @click="sortByColumn('assets')"
                              v-if="showProduct"
                              class="text-right"
                            >
                              <span>Average Assets</span>
                              <span>
                                <i
                                  :class="[
                                    'fa fa-regular sort-icon',
                                    getSortIconClass('assets')
                                  ]"
                                >
                                </i>
                              </span>
                            </th>
                            <th
                              @click="sortByColumn('market_measure')"
                              class="text-right"
                            >
                              <span>Market Total</span>
                              <span>
                                <i
                                  :class="[
                                    'fa fa-regular sort-icon',
                                    getSortIconClass('market_measure')
                                  ]"
                                >
                                </i>
                              </span>
                            </th>
                            <th
                              @click="sortByColumn('market_percent')"
                              class="text-right"
                            >
                              <span>% of Market</span>
                              <span>
                                <i
                                  :class="[
                                    'fa fa-regular sort-icon',
                                    getSortIconClass('market_percent')
                                  ]"
                                >
                                </i>
                              </span>
                            </th>
                            <th class="text-right">% of Market</th>
                          </tr>
                        </thead>
                        <tbody
                          ref="reportTableBody"
                          v-if="regulatedTableData.length"
                        >
                          <tr
                            v-for="(data, rank) in regulatedTableData"
                            :key="rank"
                          >
                            <td>
                              {{ rank + 1 }}
                            </td>
                            <td class="name-column" :title="data.vendor">
                              {{ data.vendor }}
                            </td>
                            <td class="name-column" :title="data.product">
                              {{ data.product }}
                            </td>
                            <td class="text-right">
                              {{
                                parseFloat(data.avg_assets) | formatValue('N0')
                              }}
                            </td>
                            <!-- v-if="showLink(data)" -->
                            <td class="text-right pt-2" v-if="showLink(data)">
                              <b-button
                                variant="link"
                                @click="
                                  showFi(
                                    data.product_id,
                                    data.vendor_id,
                                    data.bank_service_id,
                                    data.vendor,
                                    data.product
                                  )
                                "
                                class="product-link p-0"
                              >
                                {{ data.market_measure | numberFormat }}
                              </b-button>
                            </td>
                            <td class="text-right  pt-2" v-else>
                              {{ data.market_measure | numberFormat }}
                            </td>
                            <td class="text-right">
                              {{
                                parseFloat(data.market_percent)
                                  | formatValue('P1')
                              }}
                            </td>
                            <td
                              class="p-0"
                              :rowspan="regulatedTableData.length + 1"
                              v-if="rank === 0"
                            >
                              <div
                                class="report-chart-container"
                                ref="reportChartContainer"
                                v-if="showChart"
                              >
                                <div
                                  id="report-chart-ms"
                                  ref="reportChart"
                                ></div>
                              </div>
                            </td>
                          </tr>
                          <tr class="report-footer">
                            <td class="rank-col"></td>
                            <td colspan="2">Grand Total</td>
                            <td class="text-right">
                              {{
                                parseFloat(reportTotal.avg_assets)
                                  | formatValue('N0')
                              }}
                            </td>
                            <td class="text-right">
                              {{
                                parseFloat(reportTotal.market_measure)
                                  | numberFormat
                              }}
                            </td>
                            <td class="text-right">100%</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5">
                              <div class="text-center text-muted">
                                No Records found
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <!-- <div v-if="showVPInfo"> -->
          <b-card
            class="std-border metrics-card mt-4 container--advisor-report"
            body-class="px-3 pb-3 section--pg-members sub-card"
            header-class="pl-3"
            v-if="showVPInfo"
          >
            <div slot="header">
              <div>
                <h2 class=" section--heading mb-0 d-inline">
                  <b>{{ getVPInfoHeader }}</b>
                </h2>
              </div>
              <h5 class="section--heading-note mb-0 d-inline">
                <b>{{ subHeader }}</b>
              </h5>
              <div class="row">
                <div class="col-sm-12">
                  <span class="" v-if="this.filterMessage.trim() !== ``">
                    <span class="float-right pl-2 stop-wrap">{{
                      this.filterMessage
                    }}</span>
                    <span class="float-right font-weight-bold text-muted">
                      <i class="fa fa-filter" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class=" sub-card-content p-3">
              <div class="market-report-header">
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group
                      label="Search"
                      :label-cols="2"
                      :horizontal="true"
                    >
                      <b-form-input v-model="searchText"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 pt-2">
                    <i
                      class="fa fa-download fa-2x float-right"
                      @click="downloadReport"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="market-report-container ">
                <div class="table-responsive vp_info_table">
                  <b-table
                    v-if="fiVPInformation.length"
                    :items="fiVPInformation"
                    :fields="fieldsList"
                    :filter="searchText"
                    :sort-compare="sortPeerMetrics"
                    :show-empty="true"
                    empty-text="No records found."
                    empty-filtered-text="There are no records matching the filter."
                    @filtered="filteredList"
                    responsive="md"
                    striped
                    hover
                    small
                  >
                  </b-table>
                </div>
                <div v-if="filteredMetrics" class="text-muted py-0">
                  Showing {{ filteredMetrics }} of
                  {{ fiVPInformation.length }} entries.
                </div>
              </div>
            </div>
          </b-card>
          <!-- </div> -->
        </template>
      </PageCardWithPeerGroup>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import PageCardWithPeerGroup from '../../components/layouts/PageCardWithPeerGroup'

import Multiselect from 'vue-multiselect'
import Highcharts from 'highcharts'
import downloadsUtil from '@/modules/core/api/downloads'
// mixins
import ReportUtil from '@/modules/market_reports/mixins/reportUtil'
import Excel from 'exceljs'
export default {
  name: 'top-technologies',
  components: {
    PageCardWithPeerGroup,
    Multiselect
  },
  mixins: [ReportUtil],
  beforeCreate() {
    let institution = store.getters['Advisor/selectedInstitution']
    let peerGroup = store.getters['Advisor/selectedPeerGroup']

    if (!institution.id) {
      Vue.toasted.show('Please select institution.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/advisor/select_institution')
    } else if (!peerGroup.id) {
      Vue.toasted.show('Please select peer group.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/advisor/select_peer_group')
    }
    this.$nextTick(() => {
      this.fiVPInformation = []
      this.initData()
      this.getMarketShareReports()
      this.getCoreVendorProducts()
    })
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(() => {
      this.fiCoreVendor = this.selectedCoreVendorProducts
    })
  },
  data() {
    return {
      fiVPInformation: [],
      fiCoreVendor: [],
      orderSolution: false,
      userSolutionLoaded: false,
      allSolutionLoaded: false,
      allTreeData: null,
      showChart: true,
      searchText: null,
      filteredMetrics: null,
      subHeader: '',
      isAllBanks: false,
      sortOrder: {
        vendor: ['none', 'string'],
        product: ['none', 'string'],
        assets: ['none', 'float'],
        market_measure: ['none', 'float'],
        market_percent: ['none', 'float']
      },
      fieldsList: [
        {
          key: 'name',
          label: 'Financial Institution',
          sortable: true,
          thClass: 'bank-name-col pb-3'
        },
        {
          key: 'city',
          label: 'City',
          sortable: true,
          thClass: 'city-name-col pb-3'
        },
        { key: 'state', label: 'State', sortable: true, thClass: 'pb-3' },
        {
          key: 'vendor_name',
          label: 'Vendor',
          sortable: true,
          thClass: 'pb-3'
        },
        {
          key: 'product_name',
          label: 'Product',
          sortable: true,
          thClass: 'pb-3'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'selectedInstitution',
      'selectedPeerGroup',
      's3LogoUrl',
      'reportProgress'
    ]),
    ...mapGetters('marketShareReport', [
      'getTechnographicsOptions',
      'selectedFiltersMessage',
      'reportSubTitle'
    ]),
    ...mapState('marketShareReport', [
      'solutionId',
      'reportLoading',
      'reportData',
      'topVendorsMax',
      'reportTotal',
      'reportOtherData',
      'topVendors',
      'showProduct',
      'marketMeasure',
      'fiType',
      'coreVendorProducts',
      'selectedCoreVendorProducts'
    ]),

    showVPInfo() {
      return this.fiVPInformation.length > 0
    },
    selectedSolution() {
      return this.getTechnographicsOptions.find(
        opt => opt.id == this.solutionId
      )
    },
    isSolutionSelected() {
      return this.solutionId && this.solutionId !== undefined
    },
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }
      return regulatedData
    },
    // allVendorsSelected() {
    //   return this.fiCoreVendor.length === this.coreVendorProducts.length
    // },
    getVPInfoHeader() {
      return this.isAllBanks
        ? this.selectedInstitution.fi_type === 'credit_union'
          ? 'All CU'
          : 'All Banks'
        : 'Peer Group: ' + this.selectedPeerGroup.name
    },
    filterMessage() {
      return this.fiCoreVendor.map(v => v.name).join(', ')
    },
    reportSubTitleAdvisor() {
      return this.isAllBanks
        ? this.selectedInstitution.fi_type === 'credit_union'
          ? this.reportSubTitle.replace('Credit Unions', 'All Credit Unions')
          : this.reportSubTitle.replace('Banks', 'All Banks')
        : this.reportSubTitle + ': ' + this.selectedPeerGroup.name
    }
  },
  methods: {
    ...mapMutations('marketShareReport', [
      'updateSolution',
      'setTopVendors',
      'updateShowProduct',
      'updateFiAssetGroup',
      'updateMarketMeasure',
      'updateFiType',
      'updateKnownVendor',
      'updateCoreVendors',
      'setPeerGroupId',
      'setIsAdvisor'
    ]),
    ...mapActions('marketShareReport', [
      'getMarketShareReports',
      'getCoreVendorProducts'
    ]),
    bankOrCu(isBank) {
      return isBank ? 'Bank' : 'Credit Union'
    },
    setCoreVendor() {
      this.fiVPInformation = []
      this.setDefaults()
      this.updateCoreVendors(this.fiCoreVendor)
      this.getMarketShareReports()
    },
    initData() {
      this.updateSolution(134)
      this.updateCoreVendors([])
      this.updateKnownVendor(1)
      this.setIsAdvisor(true)
      this.updateFiType(
        this.selectedInstitution.fi_type === 'credit_union' ? 2 : 1
      )
      this.updateFiAssetGroup([])
      this.updateMarketMeasure(1)
      this.updateShowProduct(true)
      this.setDefaults()
    },
    setDefaults() {
      this.setPeerGroupId(this.isAllBanks ? null : this.selectedPeerGroup.id)
      this.setTopVendors(5)
    },
    updateTechnographic(selectedOption) {
      this.setDefaults()
      this.updateSolution(selectedOption.id)
      this.fiVPInformation = []
      this.getMarketShareReports()
    },
    peerGroupUpdated(isAllBanks) {
      this.isAllBanks = isAllBanks
      this.setDefaults()
      //this.updateKnownVendor(this.selectedPeerGroup.type == 'custom' ? 0 : 1)
      this.fiVPInformation = []
      this.getMarketShareReports()
    },

    showLink(data) {
      return (
        data.product_id !== null && this.showProduct && data.vendor !== 'Others'
      )
    },
    // filterQuery(data) {
    //   let queryObj = {
    //     vendor_id: data.vendor_id,
    //     bank_service_id: this.solutionId
    //   }
    //   if (this.fiType !== 0) {
    //     queryObj.fi_type = this.fiType
    //   }
    //   if (this.assetMin !== null) {
    //     queryObj.asset_min = this.assetMin
    //   }
    //   if (this.assetMax !== null) {
    //     queryObj.asset_max = this.assetMax
    //   }
    //   return queryObj
    // },
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    },
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-ms'
        ) {
          chart.destroy()
        }
      })
      Highcharts.chart('report-chart-ms', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            dataLabels: {
              enabled: false
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, 'P1')
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.market_percent)
            ),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    // trackReportView() {
    //   this.$ahoy.track('report_view', {
    //     bank_service_id: this.solutionId || 'default',
    //     report_type: 'FinTech Market Reports',
    //     category: 'Market Share'
    //   })
    // },
    // selectAllText() {
    //   return this.allVendorsSelected ? 'Clear All' : 'Select All'
    // },
    // selectOrRemoveAll() {
    //   if (this.allVendorsSelected) this.fiCoreVendor = []
    //   else {
    //     let allProducts = []
    //     this.coreVendorProducts.forEach(group =>
    //       allProducts.push(...group.products)
    //     )
    //     this.fiCoreVendor = allProducts
    //   }
    // },
    showFi(productId, vendorId, bankServiceId, vendorName, productName) {
      this.searchText = null
      this.subHeader = this.selectedSolution.name
      if (vendorName != null && productName != null) {
        this.subHeader += ': ' + vendorName + ' - ' + productName
      }
      this.$http
        .get('api/bank_service_products/' + productId + '/bank_list', {
          params: {
            vendor_id: vendorId,
            bank_service_id: bankServiceId,
            peer_group_id: this.isAllBanks ? null : this.selectedPeerGroup.id,
            core_vendors: this.fiCoreVendor.map(fg => fg.key),
            fi_type:
              this.selectedInstitution.fi_type === 'credit_union'
                ? 'CU'
                : 'Bank'
          },
          handleErrors: true
        })
        .then(res => {
          this.fiVPInformation = res.data.banks
          this.filteredMetrics = this.fiVPInformation.length
        })
    },
    sortPeerMetrics(record1, record2, field) {
      let columnCompare
      let fieldNames = {
        name: 'name',
        city: 'city',
        state: 'state'
      }
      let field1 = record1[fieldNames[field]] || ''
      let field2 = record2[fieldNames[field]] || ''
      columnCompare = field1
        .toString()
        .localeCompare(field2.toString(), undefined, { numeric: true })
      if (columnCompare === 0) {
        return record1.name < record2.name
          ? -1
          : record1.name > record2.name
          ? 1
          : 0
      } else {
        return columnCompare
      }
    },
    filteredList(list) {
      this.filteredMetrics = list.length
    },
    downloadReport() {
      const workbook = new Excel.Workbook()
      const sheet = workbook.addWorksheet('Partner Products')
      sheet.addRow([
        'Copyright ' +
          new Date().getFullYear() +
          " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
      ])
      sheet.addRow()
      sheet.addRow([this.getVPInfoHeader])
      sheet.addRow([this.subHeader])
      sheet.addRow(
        this.filterMessage == '' ? '' : ['Filter: ' + this.filterMessage]
      )
      sheet.addRow()
      sheet.columns = [
        { key: 'name', width: 30 },
        { key: 'city', width: 30 },
        { key: 'state', width: 30 },
        { key: 'vendor', width: 30 },
        { key: 'product', width: 30 }
      ]
      sheet.getRow(7).values = [
        'Name',
        'City',
        'State',
        'Vendor Name',
        'Product Name'
      ]

      sheet.getRow(7).eachCell(cell => {
        cell.style = {
          font: {
            name: 'Calibri',
            bold: true,
            size: 14
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE4E5E6' }
          },
          border: {
            top: { style: 'double', color: { argb: 'FF525252' } },
            left: { style: 'double', color: { argb: 'FF525252' } },
            bottom: { style: 'double', color: { argb: 'FF525252' } },
            right: { style: 'double', color: { argb: 'FF525252' } }
          }
        }
      })

      this.fiVPInformation.forEach(item => {
        sheet.addRow({
          name: item.name,
          city: item.city,
          state: item.state,
          vendor: item.vendor_name,
          product: item.product_name
        })
      })

      downloadsUtil.downloadAsExcel(workbook, `Institution Details`)
    },
    clearAllCoreVendor() {
      this.fiCoreVendor = []
    }
  },
  watch: {
    technographic: {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.solutionId = newVal.id
          this.setDefaults()
          this.getMarketShareReports()
        }
      }
    }
  }
}
</script>
<style lang="scss" src="../../../../../scss/_advisor-custom.scss"></style>
<style lang="scss">
.multiselect__element {
  span {
    white-space: pre;
  }
}
</style>
<style lang="scss" scoped>
.sub-card {
  background-color: #f0f3f5;
  .sub-card-content {
    border: solid 1px lightgray;
    background-color: white;
  }
}
.select-icon {
  color: lightgray;
  font-size: 1em;
  padding-left: 0.25em;
  cursor: pointer;
}
.sort-icon {
  padding: 0.25rem;
}
.report-title {
  font-size: 20px;
  font-weight: 500;
  .multiselect {
    width: fit-content !important;
  }
  .multiselect .multiselect__content-wrapper {
    width: fit-content !important;
    border-top: 1px solid #e8e8e8 !important;
  }

  .multiselect--active .multiselect__tags {
    border-bottom: none !important;
  }
  .multiselect__option {
    width: 100% !important;
  }
}

.market-report-container {
  margin-top: 1em;
  thead tr th {
    border-bottom: 1px solid black;
  }
  tbody tr {
    line-height: 0.9em;
    td.name-column {
      white-space: nowrap;
      max-width: 18em;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn:focus {
      box-shadow: none;
      //text-decoration: none;
    }
  }
  .report-footer {
    border-top: 1px solid black;
    font-weight: bold;
    background-color: #f4f4f4;
  }
  td.market_percent_chart {
    padding: 0;
    width: 35%;
    .progress {
      background-color: initial;
      padding-top: 1px;
      padding-left: 3em;
      height: 3em;
    }
  }
  .vp_info_table {
    max-height: 25rem;
  }
}
.core-vendor {
  min-width: fit-content;
  padding-top: 0.75rem;
  padding-right: 0.5rem;
}
.select-all-option {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.bank-name-col {
  width: 25%;
}

.city-name-col {
  width: 15%;
}
.stop-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  inline-size: min-content;
  max-width: 99%;
  display: inline-block;
}

//Uncomment to show entire table - without scroll
// @media print {
//   .vp_info_table {
//     height: auto !important;
//     max-height: none !important;
//   }
// }
</style>
