import { mapState, mapGetters, mapMutations } from 'vuex'
// utilities
import _ from 'lodash'
const ReportUtil = {
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),

    ...mapGetters(['isAsideOpen'])
  },
  methods: {
    ...mapMutations('marketShareReport', [
      // 'addToListenerList',
      'setReportData'
    ]),
    sortByColumn(colName) {
      let order = this.sortOrder[colName][0]
      order = order === 'none' ? 'asc' : order === 'asc' ? 'desc' : 'asc'
      let orderFn = function(item) {
        return parseFloat(item[colName])
      }
      if (this.sortOrder[colName][1] === 'string')
        orderFn = function(item) {
          return item[colName].toLowerCase()
        }
      else if (this.sortOrder[colName][1] === 'metric')
        orderFn = function(item) {
          return parseFloat(item.metric_values[colName][0])
        }
      this.setReportData(_.orderBy(this.reportData, orderFn, order))
      this.sortOrder[colName][0] = order
    },
    getSortIconClass(colName) {
      return this.sortOrder[colName] === undefined ||
        this.sortOrder[colName][0] === 'none'
        ? 'fa-sort'
        : this.sortOrder[colName][0] === 'asc'
        ? 'fa-sort-up'
        : 'fa-sort-down'
    },
    marginTopValue() {
      let divisor = this.regulatedTableData.length > 70 ? 1.25 : 1.5
      return (this.regulatedTableData.length / divisor) * -1
    },
    chartHeight() {
      if (this.$refs.reportTableBody) {
        return this.$refs.reportTableBody.offsetHeight
      } else {
        return 0
      }
    },
    pointPlacementValue() {
      return this.regulatedTableData.length / -125
    },
    redrawChartOnPrint() {
      setTimeout(
        function() {
          if (window.matchMedia) {
            let mediaQueryList = window.matchMedia('print')
            let listenerFn = () => {
              if (typeof this.drawChart === 'function') {
                this.drawChart()
              }
            }

            this.fn = listenerFn
            mediaQueryList.addListener(this.fn)
          }
        }.bind(this),
        0
      )
    },
    clearListener() {
      setTimeout(
        function() {
          if (window.matchMedia) {
            let mediaQueryList = window.matchMedia('print')

            mediaQueryList.removeListener(this.fn)
          }
        }.bind(this),
        0
      )
    }
  },
  watch: {
    reportData: {
      immediate: true,
      handler: function(newVal) {
        if (newVal.length) {
          this.$nextTick(() => {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          })
        }
      }
    },

    isSidebarExpanded: {
      handler: function() {
        setTimeout(
          function() {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          }.bind(this),
          200
        )
      }
    },
    isAsideOpen: {
      handler: function() {
        setTimeout(
          function() {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          }.bind(this),
          200
        )
      }
    }
  }
}
export default ReportUtil
