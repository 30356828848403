<template>
  <b-modal
    size="lg"
    id="offering-info"
    class="advisor-info-modal"
    title="Offering"
    ok-only
  >
    <div>
      <p>
        To assist financial institution executive management in the assessment
        of their customer offering, {{ siteName }} has developed the industry’s
        most extensive solution hierarchy. Harnessing big data in an innovative
        way, {{ siteName }} analyzes every financial institutions’ primary
        website and other data sources monthly to identify whether the
        institution offers the solution and, when applicable, the vendor-product
        utilized.
      </p>
      <p>
        Principally focusing on retail and business solutions, the hierarchy
        covers nearly 300 solutions ranging from channels to payments to lending
        products. The hierarchy is evolving rapidly with additions monthly to
        allow executives to stay abreast of continual innovations in banking’s
        digital transformation. In addition to retail and business solutions
        coverage, {{ siteName }} has extended focus to operating, non-customer
        facing solutions such as core processing. We expect this solution
        roadmap to continue to evolve rapidly and encourage suggested on
        individual solutions or categories from our client executives.
      </p>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SolutionHierarchyOfferingModal',
  computed: {
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return this.partnerSite ? 'Performance Intelligence' : 'FI Navigator'
    }
  }
}
</script>
