<template>
  <PageCardWithPeerGroup
    :pageName="reportType + ' Report'"
    :logoUrl="s3LogoUrl"
    :institutionName="selectedInstitution.name"
    :cardBodyClasses="['page-content']"
  >
    <template slot="page_content">
      <v-wait for="generatingReport">
        <template slot="waiting">
          <ReportPlaceholder
            :progress="reportProgress || 0"
          ></ReportPlaceholder>
        </template>
        <div v-if="loadContent" class="container--advisor-report">
          <DecisionTree
            :reportId="reportId"
            :reportType="reportType"
            :reportSubType="reportSubType"
            :productId="reportProductId"
            :treeId="reportTreeId"
            :bankServiceId="reportBankServiceId"
            :metricId="selectedMetricId"
            @print-page="$emit('print-page')"
            @updateMetricId="updateMetricId"
            ref="decisionTree"
          ></DecisionTree>
          <StatusCheck
            :metricId="selectedMetricId"
            @peerGroupUpdated="peerGroupUpdated"
            @mergerRestateChanged="updateDecisionTreeData"
          ></StatusCheck>
          <ActionPlan
            :treeId="reportTreeId"
            :productId="reportProductId"
            :bankServiceId="reportBankServiceId"
            :metricActionPlan="true"
            :pageName="reportType + 'Report'"
            class="metric-report-action-plan"
          ></ActionPlan>
        </div>
      </v-wait>
    </template>
  </PageCardWithPeerGroup>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ReportPlaceholder from '../components/ReportGeneratorPlaceholder'
import PageCardWithPeerGroup from '../components/layouts/PageCardWithPeerGroup'
import DecisionTree from '../components/DecisionTree'
import StatusCheck from '../components/StatusCheck'
import ActionPlan from '../components/ActionPlan'

export default {
  name: 'profit-macro-profitability',
  props: {
    reportType: {
      type: String,
      required: true
    },
    reportSubType: {
      type: String,
      required: true
    }
  },
  components: {
    ReportPlaceholder,
    PageCardWithPeerGroup,
    DecisionTree,
    StatusCheck,
    ActionPlan
  },
  created() {
    let chosenMetricId =
      this.$route.query.metricId ||
      this.metricId(this.reportType, this.reportSubType)
    this.setMetricId(chosenMetricId)

    if (!this.reportId) {
      this.$wait.start('generatingReport')
      this.getMenuData().then(() => {})
    }
  },
  beforeMount() {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('Advisor', [
      'selectedInstitution',
      'selectedMetricId',
      's3LogoUrl',
      'reportId',
      'productId',
      'setId',
      'treeId',
      'metricId',
      'bankServiceId',
      'reportToken',
      'reportStatus',
      'reportProgress'
    ]),
    reportProductId() {
      return this.productId(this.reportType)
    },
    reportTreeId() {
      return this.treeId(this.reportType)
    },
    reportBankServiceId() {
      return this.bankServiceId(this.reportType, this.reportSubType)
    },
    loadContent() {
      return this.reportId && this.reportProgress === 100
    }
  },
  methods: {
    ...mapActions('Advisor', ['getMenuData', 'getDecisionTreeData']),
    ...mapMutations('Advisor', ['setMetricId']),
    updateMetricId(metricId) {
      this.setMetricId(metricId)
    },
    peerGroupUpdated() {
      this.$wait.start('generatingReport')
      this.getMenuData().then(() => {
        this.$ahoy.track('report_view', {
          fi_id: this.selectedInstitution.id,
          report_id: this.reportId,
          report_type: 'FIN-A'
        })
      })
    },
    updateDecisionTreeData() {
      this.$refs.decisionTree.updateTreeData()
    }
  },
  watch: {
    reportProgress: function(newVal) {
      newVal !== 100
        ? this.$wait.start('generatingReport')
        : this.$wait.end('generatingReport')
    }
  }
}
</script>

<style lang="scss" src="../../../../scss/_advisor-custom.scss"></style>

<style lang="scss" scoped></style>
