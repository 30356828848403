<template>
  <b-modal
    v-model="showModal"
    @show="setInitData"
    modal-class="peer-table-modal"
    size="lg"
    no-close-on-esc
  >
    <template slot="modal-header">
      <div class="row">
        <b-col sm="12" md="6" class="pl-2 pr-2">
          <h4 class="mb-0">Peer Group View</h4>
          <div>{{ peerGroupName }}</div>
        </b-col>
        <b-col sm="10" md="5" class="pl-2 pr-3">
          <h4 class="mb-0">{{ peerTableSolutionParent }}</h4>
          <div>{{ peerTableSolutionChild }}</div>
        </b-col>
        <b-col
          sm="2"
          md="1"
          class="text-left cursor-pointer"
          @click="downloadXlsx"
        >
          <i class="fa fa-file-excel-o fa-2x" title="Download xlsx"></i>
          <br />
          <u>xlsx</u>
        </b-col>
      </div>
      <button type="button" class="close" @click="closeModal">x</button>
    </template>
    <v-wait for="fetchingPeerGroup">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="10"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="peer-group-table">
        <div class="service-details">
          <div class="service-header">
            <div class="row">
              <label
                class="col-md-9 service-header-bank header-label font-weight-bold"
                >Financial Institution</label
              >
              <label
                class="col-md-3 service-header-bank-web header-label font-weight-bold"
                >Website</label
              >
            </div>
          </div>
          <div class="service-header">
            <ul class="has-service-ul">
              <li class="has-service-li" v-if="!peerGroups.length">
                <div class="row">
                  <div class="col-md-12 text-center">No Information Found</div>
                </div>
              </li>
              <li
                class="has-service-li"
                v-for="peerGroup in peerGroups"
                :key="peerGroup.bank_id"
              >
                <div class="row">
                  <div
                    class="col-md-9 service-name-container"
                    :title="peerGroup.bank_name"
                  >
                    <span
                      :class="{ green: peerGroup.has_service }"
                      class="has-service"
                    ></span>
                    <span
                      >{{ peerGroup.bank_name }} - {{ peerGroup.city }},
                      {{ peerGroup.state }}</span
                    >
                  </div>
                  <div class="col-md-3">
                    <div class="view-solution">
                      <a
                        target="_blank"
                        v-if="peerGroup.url"
                        :href="formatUrl(peerGroup.url)"
                        >View Solution</a
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-wait>
    <div slot="modal-footer" class="">
      <div class="row">
        <div class="col-sm-12 text-right">
          <b-button size="" variant="secondary" class="" @click="closeModal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SolutionHierarchyPeerTableModal',
  props: {
    showModal: {
      required: true,
      type: Boolean,
      default: false
    },
    reportId: {
      required: true
    },
    peerTableSolutionParent: {
      required: true
    },
    peerTableSolutionChild: {
      required: true
    },
    selectedBankService: {
      required: true
    }
  },
  data() {
    return {
      peerGroups: [],
      peerGroupName: ''
    }
  },
  computed: {
    ...mapGetters('Advisor', ['setId'])
  },
  methods: {
    setInitData() {
      this.$wait.start('fetchingPeerGroup')
      this.$http
        .get(
          '/api/bank_services/' +
            this.selectedBankService.bank_service_id +
            '/peer_services',
          {
            params: {
              report_id: this.reportId,
              set_id: this.setId
            },
            handleErrors: true
          }
        )
        .then(res => {
          this.peerGroups = res.data.peer_services
          this.peerGroupName = res.data.peer_group_name
        })
        .then(() => {
          this.$wait.end('fetchingPeerGroup')
        })
    },
    downloadXlsx() {
      this.$http
        .get(
          '/api/bank_services/' +
            this.selectedBankService.bank_service_id +
            '/peer_services.xlsx',
          {
            params: {
              report_id: this.reportId,
              set_id: this.setId
            },
            handleErrors: true,
            responseType: 'blob'
          }
        )
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'peer_group_services.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .then(() => {})
    },
    closeModal() {
      this.$emit('closeModal')
    },
    formatUrl(url) {
      if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        return url
      } else if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
        return url.replace(/"/g, '')
      }
      return 'https://' + url.replace(/"/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.peer-table-modal {
  .modal-header {
    .row {
      width: 100%;
    }
  }
}

.peer-group-table .service-name-container {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
.peer-group-table .service-header .service-header-bank {
  padding-left: 2.25rem;
}
.peer-group-table .service-header .service-header-bank-web {
  padding-left: 1.9rem;
}
.peer-group-table .service-header {
  padding: 0.25rem 0;
}
.peer-group-table .has-service-ul {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0px;
  margin-bottom: 10px;
}
.peer-group-table .has-service-li {
  padding: 0.125rem 0;
}
.peer-group-table .has-service {
  display: inline-block;
  margin-right: 0.5rem;
}
.peer-group-table .has-service.green:before {
  background-color: rgba(141, 196, 74, 0.81);
}
.peer-group-table .has-service:before {
  background-color: #cfd2d4;
  content: ' ';
  border-radius: 100%;
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
  vertical-align: middle;
}
.peer-group-table .view-solution {
  font-size: 0.75rem;
}
</style>
