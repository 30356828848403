<template>
  <div class="status-chart">
    <div id="doughnut-chart" class="dc-container text-center pt-3"></div>
    <div class="row status-chart--metrics px-2 justify-content-center">
      <div class="col-sm-12 col-lg-8">
        <div class="row">
          <div class="col-sm-6">Differential</div>
          <div class="col-sm-6 text-right">{{ differential }}</div>
        </div>
        <div class="row">
          <div class="col-sm-6">{{ costLabel }}</div>
          <div class="col-sm-6 text-right">{{ metricCost }}</div>
        </div>
        <div class="row">
          <div class="col-sm-6">Trend</div>
          <div class="col-sm-6 text-right">
            <span><i :class="trendClass[metricTrend]"></i></span>
            {{ metricTrend }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PerformanceStatus',
  props: {
    metricDetail: {
      type: Object,
      required: true
    },
    reportDetail: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    breakPoints: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      doughnutChart: null
    }
  },
  mounted() {
    this.drawChart()
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters('Advisor', ['isSelectedCreditUnion']),
    classifiedReport() {
      return this.reportDetail.is_classified
    },
    metricLevel() {
      if (!this.classifiedReport) {
        return 'UNCLASSIFIED'
      }

      if (this.reportDetail.class_value < 2) {
        return 'EXCELLENT'
      } else if (this.reportDetail.class_value < 3) {
        return 'STRONG'
      } else if (this.reportDetail.class_value < 4) {
        return 'SOLID'
      } else if (this.reportDetail.class_value < 5) {
        return 'MODERATE'
      } else if (this.reportDetail.class_value < 5.99) {
        return 'TRAILING'
      }
    },
    metricTrend() {
      if (this.reportDetail.trend > 0) {
        return 'UP'
      } else if (this.reportDetail.trend < 0) {
        return 'DOWN'
      } else {
        return 'FLAT'
      }
    },
    metricCost() {
      return this.reportDetail.cost
        ? this.getReportCost()
        : this.getDefaultCost()
    },
    costLabel() {
      return this.reportDetail.cost
        ? this.getReportCostLabel()
        : this.getDefaultCostLabel()
    },
    differential() {
      let diff = this.reportDetail.diff || this.reportDetail.class_diff
      return this.$options.filters.formatValue(diff, this.metricDetail.format)
    },
    trendClass() {
      return {
        UP: 'fa fa-arrow-up pr-1',
        DOWN: 'fa fa-arrow-down pr-1',
        FLAT: ''
      }
    },
    metricStrength() {
      let classification = Math.floor(this.reportDetail.class_value)
      let strength =
        (5 - classification) * 10.0 +
        (100.0 - (this.reportDetail.class_value - classification) * 100.0) /
          10.0
      return Math.min(strength * 2, 100)
    },
    breakPointLabels() {
      return this.breakPoints.map(
        (breakPoint => {
          return this.$options.filters.formatValue(
            breakPoint.value,
            this.metricDetail.format
          )
        }).bind(this)
      )
    },
    colorCodes() {
      return this.partnerSite
        ? ['#216D8E', '#8CD033', '#3766CB', '#FFBB34', '#E85E3A']
        : ['#004D00', '#349C34', '#2F78BA', '#DEC000', '#D64800']
    }
  },
  methods: {
    getReportCost() {
      let cost = Math.abs(this.reportDetail.cost)
      if (this.metricDetail.is_class_based === 0) {
        let divident = this.isSelectedCreditUnion ? 1000000 : 1000
        cost = cost ? `$${(cost / divident).toFixed(3)}` : (0.0).toFixed(3)
      } else {
        cost = cost ? cost.toFixed(3) : (0.0).toFixed(3)
      }
      return cost
    },
    getDefaultCost() {
      let cost = (0.0).toFixed(3)
      return this.metricDetail.is_score_metric ? cost : '$' + cost
    },
    getReportCostLabel() {
      if (this.metricDetail.is_score_metric) {
        return this.reportDetail.cost < 0 ? 'Cost' : 'Benefit'
      } else {
        return this.reportDetail.cost < 0 ? 'Cost (M)' : 'Benefit (M)'
      }
    },
    getDefaultCostLabel() {
      return this.metricDetail.is_score_metric ? 'Cost' : 'Cost (M)'
    },
    reflowChart() {
      setTimeout(
        function() {
          this.doughnutChart.reflow()
        }.bind(this),
        250
      )
    },
    drawChart() {
      this.doughnutChart = Highcharts.chart('doughnut-chart', {
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        title: {
          text: this.metricLevel,
          verticalAlign: 'middle',
          y: 50,
          style: {
            fontSize: '0.875rem',
            color: '#4c4d4c'
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              crop: false,
              overflow: 'allow',
              style: {
                fontSize: '0.65rem',
                fontWeight: 'normal',
                color: '#4c4d4c'
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            size: '175%'
          },
          series: {
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: [
          {
            type: 'pie',
            name: 'Strength',
            innerSize: '50%',
            data: [
              {
                name: 'on',
                y: this.metricStrength,
                color: '#414142'
              },
              {
                name: 'off',
                y: 100 - this.metricStrength,
                color: '#edeced'
              }
            ]
          },
          {
            type: 'pie',
            name: 'Classification',
            innerSize: '80%',
            data: [
              {
                name: 'excellent',
                y: 20,
                color: this.classifiedReport ? this.colorCodes[4] : '#bbbdbf'
              },
              {
                name: 'strong',
                y: 20,
                color: this.classifiedReport ? this.colorCodes[3] : '#bbbdbf'
              },
              {
                name: 'solid',
                y: 20,
                color: this.classifiedReport ? this.colorCodes[2] : '#bbbdbf'
              },
              {
                name: 'moderate',
                y: 20,
                color: this.classifiedReport ? this.colorCodes[1] : '#bbbdbf'
              },
              {
                name: 'trailing',
                y: 20,
                color: this.classifiedReport ? this.colorCodes[0] : '#bbbdbf'
              }
            ]
          },
          {
            type: 'pie',
            innerSize: '90%',
            data: [
              {
                name: 'placeholder1',
                y: 5,
                color: 'white'
              },
              {
                name: this.breakPointLabels[0],
                y: 25,
                color: 'white',
                dataLabels: {
                  enabled: true,
                  rotation: -55,
                  distance: -10
                }
              },
              {
                name: this.breakPointLabels[1],
                y: 20,
                color: 'white',
                dataLabels: {
                  enabled: true,
                  rotation: -15,
                  distance: -14
                }
              },
              {
                name: this.breakPointLabels[2],
                y: 25,
                color: 'white',
                dataLabels: {
                  enabled: true,
                  rotation: 15,
                  distance: -15
                }
              },
              {
                name: this.breakPointLabels[3],
                y: 20,
                color: 'white',
                dataLabels: {
                  enabled: true,
                  rotation: 60,
                  distance: -8
                }
              },
              {
                name: 'placeholder6',
                y: 5,
                color: 'white'
              }
            ]
          }
        ]
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/_advisor-variables';

.dc-container {
  height: 12rem;
}

.status-chart {
  font-size: 0.8rem;
  color: #4c4d4c;
  font-family: $opensans_regular;
}

.fa-arrow-up {
  color: #8cc63f;
}

.fa-arrow-down {
  color: #ec1c24;
}

.status-chart--metrics {
  padding-right: 2rem;
  padding-left: 2rem;
}
</style>
